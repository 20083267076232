import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';

import { Container, ErrorMessage } from './styles';

export default function SelectUi({
  name,
  width,
  borderColor,
  iconColor,
  value,
  handleChange,
  placeholder,
  options,
  error,
  disabled,
}) {
  return (
    <Container width={width} borderColor={borderColor} iconColor={iconColor}>
      <FormControl style={{ width: '100%' }}>
        <Select
          value={value}
          onChange={handleChange}
          name={name}
          error={!!error}
          disabled={disabled}
          displayEmpty
          input={(
            <Input
              classes={{
                root: 'root',
                underline: 'underline',
              }}
            />
)}
        >
          <MenuItem value="" disabled>
            <span style={{ fontSize: '13px', marginLeft: '5px', color: '#a2a2ab' }}>
              {placeholder}
            </span>
          </MenuItem>
          {options.map(option => (
            <MenuItem key={Math.random()} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {!!error && <ErrorMessage>{error}</ErrorMessage>}
      </FormControl>
    </Container>
  );
}
