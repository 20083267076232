/* eslint-disable import/no-unresolved */
import styled from 'styled-components';

import SearchIcon from '../../assets/images/search-icon.svg';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 115px;
  padding: 0 4%;
  box-shadow: 2px 2px 5px -1px rgba(74, 74, 74, 0.06);

  .logooo {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  > img {
    height: 80px;
  }
`;

export const MobileContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  background: #fff;
  box-shadow: 2px 2px 5px -1px rgba(74, 74, 74, 0.06);
  z-index: 10;

  > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 24px;
    cursor: pointer;
    width: 100%;

    > a > img:first-of-type {
      height: 60px;
    }

    > img:last-of-type {
      transition: all ease-in-out 0.2s;
    }

    .open.active {
      display: none;
    }

    .close {
      display: none;
    }

    .active.close {
      display: inline-block;
    }
  }

  > div:last-of-type {
    display: none;
  }
`;

export const Contato = styled.div`
  display: flex;
  align-items: center;

  font-size: 12px;
  font-weight: normal;
  color: #000;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 40px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -40px;
      width: 1px;
      height: 80%;
      background: #4a4a4a;
    }

    img {
      height: 25px;
      margin-bottom: 12px;
    }

    > a {
      position: relative;
      color: #4a4a4a;
      text-decoration: none;
    }
  }

  > a {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 40px;
    color: #4a4a4a;
    text-decoration: none;

    &:first-child:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -40px;
      width: 1px;
      height: 80%;
      background: #4a4a4a;
    }

    img {
      height: 25px;
      margin-bottom: 12px;
    }
  }
`;

export const Search = styled.div`
  position: relative;
  width: 430px;
  height: 30px;

  /* &:after {
    content: url(${SearchIcon});
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    height: 17px;
  } */

  input {
    width: 100%;
    height: 100%;
    font-size: 15px;
    font-weight: normal;
    text-indent: 5px;
    color: #4a4a4a;
    border: none;
    border-bottom: 1px solid #4a4a4a;

    &::placeholder {
      font-size: 13px;
      font-weight: normal;
      color: #b3b3b3;
    }
  }
`;

export const MobileSearch = styled.div`
  position: relative;
  width: 60%;
  max-width: 430px;
  height: 30px;

  /* &:after {
    content: url(${SearchIcon});
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    height: 17px;
    background: #fff;
  } */

  input {
    width: 100%;
    height: 100%;
    font-size: 15px;
    font-weight: normal;
    text-indent: 5px;
    color: #4a4a4a;
    border: none;
    border-bottom: 1px solid #4a4a4a;

    &::placeholder {
      font-size: 13px;
      font-weight: normal;
      color: #b3b3b3;
    }
  }
`;

export const MobileMenu = styled.div`
  position: absolute;
  top: -100vh;
  width: 100%;
  height: calc(100vh - 80px);
  padding-top: 15px;
  padding-left: 24px;
  background: #fff;
  transition: all ease-in 0.2s;
  z-index: 9;

  .divider {
    width: 95%;
    height: 1px;
    background: rgb(235, 235, 235);
  }

  > div {
    margin: 35px 0;

    a {
      font-size: 15px;
      font-weight: normal;
      text-decoration: none;
      color: #4a4a4a;
    }
  }

  .contato {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: normal;
    color: #4a4a4a;

    a {
      img {
        height: 20px;
      }

      > span {
        margin-left: 15px;
      }
    }

    > div {
      display: flex;
      padding-right: 24px;

      > div {
        display: flex;

        @media (max-width: 416px) {
          flex-direction: column;
        }

        > a {
          margin-left: 15px;
        }
      }

      img {
        height: 20px;
      }

      > span {
        margin-left: 15px;
      }
    }
  }
`;
