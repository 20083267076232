import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getUnidadeRequest: ['slug'],
  getUnidadeSuccess: ['unidade'],
});

export const PagesTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  unidadePage: {
    unidade: null,
    empreendimento: null,
  },
});

/* Reducers */

export const unidadePage = (state, { unidade }) => state.merge({
  unidadePage: {
    unidade,
  },
});

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_UNIDADE_SUCCESS]: unidadePage,
});
