import styled from 'styled-components';

export const Container = styled.section``;

export const Filtro = styled.div`
  padding: 65px 15%;
  background: #33499c;

  > h2 {
    font-family: 'Noirden';
    font-size: 65px;
    font-weight: 300;
    text-align: center;
    color: #6abd45;
  }

  > p {
    font-size: 15px;
    font-weight: normal;
    text-align: center;
    color: #fff;
    margin-top: 15px;
  }

  form {
    .apartamento {
      position: relative;
      margin-top: 90px;

      > span {
        position: absolute;
        top: -20px;

        font-size: 15px;
        font-weight: bold;
        text-transform: uppercase;
        color: #fff;
      }

      div[class*='MuiInput-underline-']:hover:not(.MuiInput-disabled-15):not(.MuiInput-focused-14):not(.MuiInput-error-17):before {
        border-bottom-color: #fff;
      }

      > div {
        > div {
          > div {
            > div {
              &:before,
              &:hover {
                border-bottom-color: #fff;
              }

              &:after {
                border-bottom-color: #6abd45;
              }
              > div {
                > div {
                  p {
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }

      .jss439 {
        color: #6e81c8;
      }

      .css-1tna5xz {
        color: #fff;
      }

      .css-19bqh2r {
        color: #fff;
      }
    }

    .opcoes {
      display: grid;
      grid-template-columns: 1.5fr 0.8fr 1fr;
      grid-gap: 50px;
      margin-top: 50px;

      .errored {
        border-color: red;
      }

      .balao__input {
        width: 45%;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.3;
        }
      }

      .valor__input {
        width: 60%;
      }

      > div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > span {
          font-size: 15px;
          font-weight: bold;
          text-transform: uppercase;
          color: #fff;
        }

        > input {
          height: 36px;
          font-size: 15px;
          font-weight: normal;
          text-indent: 5px;
          color: #fff;
          border: none;
          border-bottom: 1px solid #fff;
          background: transparent;
          transition: all ease-in 0.2s;

          &::placeholder {
            font-size: 13px;
            font-weight: normal;
            color: #6e81c8;
          }

          &:focus {
            border-bottom: 1.5px solid #6abd45;
          }
        }

        .info {
          position: absolute;
          font-size: 8px;
          font-weight: normal;
          bottom: -25px;
        }

        > div {
          > span {
            font-size: 12px;
            font-weight: normal;
            text-transform: uppercase;
            color: #fff;
          }

          .radioRoot {
            color: #fff;
          }

          .radioChecked {
            color: #6abd45;
          }
        }
      }
    }

    .form__error {
      text-align: center;
      color: #cc0000;
      margin-top: 15px;
    }

    .submit {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
    }
  }

  > button {
    width: 80%;
    height: 40px;
    margin: 50px 0 0 10%;
    border: none;
    background: transparent;
    cursor: pointer;

    div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #6abd45;
      transition: all ease-in 0.2s;

      &:hover {
        background: #84d660;
      }

      span {
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        text-decoration: none;
        color: #fff;

        img {
          height: 8px;
          margin-left: 10px;
        }
      }
    }
  }
`;

export const FiltroMobile = styled(Filtro)`
  > h2 {
    font-size: 40px;
  }

  > p {
    font-size: 12px;
  }

  form {
    .opcoes {
      grid-template-columns: 1fr;

      > div {
        justify-content: unset;

        &:first-of-type {
          justify-content: space-between;
        }

        &:last-of-type {
          justify-content: space-between;
        }

        input {
          width: 70% !important;
        }
      }
    }
  }

  > button {
    div {
      span {
        font-size: 10px;
      }
    }
  }
`;

export const Resultado = styled.div`
  padding: 55px 10%;
  background: #f3f3f3;

  h2 {
    font-family: 'Noirden';
    font-size: 36px;
    font-weight: 300;
    text-align: center;
    color: #8e8e8e;
    max-width: 700px;
    margin: auto;
  }

  h3 {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #33499c;
    max-width: 1000px;
    margin: auto;
    margin-top: 55px;
  }
`;

export const ResultadoMobile = styled(Resultado)`
  .financiamento__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 45px;

    span {
      font-size: 13px;
      font-weight: normal;
      text-transform: uppercase;
      text-align: center;
      color: #606060;
    }

    a {
      img {
        height: 30px;
        margin-top: 20px;
      }
    }
  }

  .financiamento__obs {
    p {
      font-size: 12px;
      font-weight: normal;
      color: #606060;
      margin: 20px 0 20px 20px;
    }
  }
`;

export const Financiamento = styled.div`
  .financiamento__header {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    align-items: center;
    margin-top: 45px;

    span {
      font-size: 15px;
      font-weight: bold;
      text-align: center;
      color: #606060;
    }
  }

  .financiamento__opcoes {
    div {
      height: 80px;
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
      align-items: center;
      justify-content: center;
      background: #fff;
      margin-top: 10px;

      &:first-of-type {
        margin-top: 20px;
      }

      span {
        font-size: 13px;
        font-weight: normal;
        text-align: center;
        color: #606060;
      }
    }
  }

  .financiamento__obs {
    p {
      font-size: 12px;
      font-weight: normal;
      color: #606060;
      margin: 20px 0 20px 20px;
    }
  }

  .financiamento__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 45px;

    span {
      font-size: 13px;
      font-weight: normal;
      text-transform: uppercase;
      color: #606060;
    }

    a {
      img {
        height: 20px;
        margin-left: 25px;
      }
    }
  }
`;

export const FinanciamentoMobile = styled(Financiamento)`
  overflow-x: auto;
  scroll-snap-type: x proximity;
  white-space: nowrap;

  .financiamento__header {
    grid-template-columns:
      minmax(240px, 2fr) minmax(180px, 1fr) minmax(110px, 1fr) minmax(120px, 1fr)
      minmax(180px, 1fr);
  }

  .financiamento__opcoes {
    display: grid;

    > div {
      grid-template-columns:
        minmax(240px, 2fr) minmax(180px, 1fr) minmax(110px, 1fr) minmax(120px, 1fr)
        minmax(180px, 1fr);
    }
  }
`;

export const Contato = styled.div`
  padding: 55px 10%;
  background: #f3f3f3;

  h2 {
    font-family: 'Noirden';
    font-size: 36px;
    font-weight: 300;
    text-align: center;
    color: #33499c;
    max-width: 700px;
    margin: auto;
  }

  form {
    margin-top: 70px;

    > div:first-of-type {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 30px;
      margin-bottom: 40px;
    }

    > div:last-of-type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;

      .infos {
        display: flex;
        flex-direction: column;

        > span {
          font-size: 15px;
          color: #4a4a4a;

          span {
            font-weight: bold;
          }
        }
      }
    }

    button {
      position: relative;
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;
      color: #fff;
      border: none;
      align-self: center;

      width: 140px;
      height: 30px;
      background: #6abd45;
      display: flex;
      text-align: right;
      align-items: center;
      justify-content: flex-end;
      margin-left: 30px;
      transition: all ease-in 0.2s;
      cursor: pointer;

      img {
        height: 8px;
        padding: unset;
        margin: 0 10px;
      }

      a,
      > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        text-decoration: none;
        color: #fff;
      }

      &:before {
        content: '';
        position: absolute;
        left: -30px;
        width: 0px;
        height: 0px;
        border-left: 30px solid transparent;
        border-bottom: 30px solid #6abd45;
        transition: all ease-in 0.2s;
      }

      &:hover {
        background: #84d660

    &:before {
          border-bottom-color: #84d660;
        }
      }
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 75px;

    span {
      font-size: 13px;
      font-weight: normal;
      text-transform: uppercase;
      color: #606060;
    }

    a {
      img {
        height: 20px;
        margin-left: 25px;
      }
    }
  }
`;

export const ContatoMobile = styled(Contato)`
  padding: 55px 5%;

  h2 {
    font-size: 25px;
  }

  form {
    > div:first-of-type {
      grid-template-columns: 1fr;
      margin-bottom: 30px;
    }

    > div:last-of-type {
      flex-direction: column-reverse;

      .infos {
        text-align: center;
        margin-top: 30px;
      }
    }
  }

  > div {
    flex-direction: column;

    span {
      text-align: center;
    }

    a {
      img {
        height: 30px;
        margin-top: 20px;
      }
    }
  }
`;
