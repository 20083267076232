/* eslint-disable no-nested-ternary */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import Responsive from "react-responsive";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import SmoothScroll from "smooth-scroll";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SearchActions from "../../store/ducks/search";

import {
  FilterContainer,
  FilterContainerMobile,
  Container,
  ContainerMobile,
  Unidades,
  Unidade,
  UnidadeMobile
} from "./styles";

// Components
import Header from "../../components/Header";
import Filter from "../../components/Filter";
import Simulador from "../../components/Simulador";
import Footer from "../../components/Footer";
import BgButton from "../../components/BgButton";
import Loading from "../../components/Loading";

function Search({
  data,
  params,
  history,
  loading,
  total,
  totalPages,
  currentPage,
  getSearchRequest
}) {
  const Desktop = props => <Responsive {...props} minWidth={992} />;
  const Mobile = props => <Responsive {...props} maxWidth={991} />;

  useEffect(() => {
    if (!params) history.goBack();
    document.title =
      "Resultado | Vendas de apartamentos - Construtora Miquelão";
  }, [history, params]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function formatQueryParams() {
    const query = queryString.parse(params);
    const stringArr = [];
    if (!!query.tipo) {
      if (query.tipo === "na_planta") stringArr.push("na planta");
      if (query.tipo === "lancamento") stringArr.push("lançamentos");
      if (query.tipo === "pronto_morar") stringArr.push("pronto para morar");
    }

    if (!!query.bairro) {
      stringArr.push(`localizado em ${query.bairro}`);
    }

    if (!!query.preco_de && !!query.preco_ate) {
      stringArr.push(
        `entre R$ ${parseInt(query.preco_de, 10).toLocaleString(
          "pt-BR"
        )},00 e R$ ${parseInt(query.preco_ate, 10).toLocaleString("pt-BR")},00`
      );
    }

    if (!!query.quartos) {
      stringArr.push(`com ${query.quartos} quarto(s)`);
    }

    if (!!query.suites) {
      stringArr.push(`com ${query.suites} suite(s)`);
    }

    if (!!query.garagem) {
      stringArr.push(`com ${query.garagens} vaga(s) de garagem`);
    }

    if (!!query.m2) {
      stringArr.push(`mínimo de ${query.m2}m²`);
    }

    const string = stringArr.join(" - ");

    return capitalizeFirstLetter(string);
  }

  function getPage(page) {
    const query = queryString.parse(params);
    query.page = page + 1;
    const stringfy = queryString.stringify(query);
    getSearchRequest(stringfy);
  }

  function pageChangeHandle({ selected }) {
    // Fazer chamada com mesma queryString apenas adicionando page
    function scrollStops() {
      getPage(selected);
      document.removeEventListener("scrollStop", scrollStops);
    }

    document.addEventListener("scrollStop", scrollStops, false);
    const scroll = new SmoothScroll();
    scroll.animateScroll(200);
    scroll.destroy();
  }

  return (
    <>
      <Header />

      {/* Filter inicio */}
      <Desktop>
        <FilterContainer>
          <Filter avancada params={params} />
        </FilterContainer>
      </Desktop>
      <Mobile>
        <FilterContainerMobile>
          <Filter avancada params={params} />
        </FilterContainerMobile>
      </Mobile>
      {/* Filter fim */}

      {/* Unidades Inicio */}
      {!loading ? (
        <>
          <Desktop>
            <Container>
              <h3>
                {data.length === 0
                  ? "Nenhum apartamento encontrado"
                  : `${total} apartamentos encontrados`}
              </h3>
              <div className="small">{formatQueryParams()}</div>
              <Unidades>
                {data.map(unidade => (
                  <Unidade key={unidade.id} to={`/unidade/${unidade.slug}`}>
                    <img
                      src={unidade.fachada.sizes.large}
                      alt={unidade.fachada.title}
                    />
                    <div>
                      <h4>{unidade.title}</h4>
                      <p className="unidade__nome">
                        {`${unidade.empreendimento.post_title} - ${
                          unidade.bairro
                        } - ${unidade.pavimento}° pavimento`}
                      </p>
                      <p className="unidade__caracteristicas">
                        {capitalizeFirstLetter(
                          unidade.planta.caracteristicas
                            .map(item => item.caracteristica)
                            .join(", ")
                            .toLowerCase()
                        )}
                      </p>
                      <p
                        className="unidade__descricao"
                        dangerouslySetInnerHTML={{
                          __html: unidade.caracteristicas.texto
                        }}
                      />
                      <div className="status__valor">
                        <div
                          className="unidade__status"
                          style={{
                            background:
                              unidade.status === "disponivel"
                                ? "#6abd45"
                                : unidade.status === "reservado"
                                ? "darkorange"
                                : unidade.status === "vendido"
                                ? "firebrick"
                                : null
                          }}
                        >
                          {unidade.status}
                        </div>
                        <p className="unidade__valor">
                          R${" "}
                          <span>
                            {parseInt(unidade.valor, 10).toLocaleString(
                              "pt-BR"
                            )}
                          </span>
                          ,00
                        </p>
                      </div>
                    </div>

                    <div>
                      <img
                        src={unidade.logo.sizes.large}
                        alt={unidade.logo.title}
                      />
                      <BgButton
                        to={`/unidade/${unidade.slug}`}
                        width="190px"
                        bgColor="#6abd45"
                        bgColorHover="#84d660"
                      >
                        Saiba Mais
                      </BgButton>
                    </div>
                  </Unidade>
                ))}
              </Unidades>
              {total !== 0 && (
                <ReactPaginate
                  pageCount={totalPages}
                  pageRangeDisplayed={5}
                  marginPagesDisplayed={2}
                  forcePage={currentPage}
                  onPageChange={page => pageChangeHandle(page)}
                  containerClassName="pagination__container"
                  previousLabel="Anterior"
                  nextLabel="Próximo"
                />
              )}
            </Container>
          </Desktop>

          <Mobile>
            <ContainerMobile>
              <h3>
                {data.length === 0
                  ? "Nenhum apartamento encontrado"
                  : `${total} apartamentos encontrados`}
              </h3>
              <div className="small">{formatQueryParams()}</div>
              <Unidades>
                {data.map(unidade => (
                  <UnidadeMobile
                    key={unidade.id}
                    to={`/unidade/${unidade.slug}`}
                  >
                    <img
                      src={unidade.fachada.sizes.large}
                      alt={unidade.fachada.title}
                    />
                    <div>
                      <h4>{unidade.title}</h4>
                      <p className="unidade__nome">
                        {`${unidade.empreendimento.post_title} - ${
                          unidade.bairro
                        }`}
                      </p>
                      <p className="unidade__caracteristicas">
                        {capitalizeFirstLetter(
                          unidade.planta.caracteristicas
                            .map(item => item.caracteristica)
                            .join(", ")
                            .toLowerCase()
                        )}
                      </p>
                      <p
                        className="unidade__descricao"
                        dangerouslySetInnerHTML={{
                          __html: unidade.caracteristicas.texto
                        }}
                      />
                      <p className="unidade__valor">
                        R${" "}
                        <span>
                          {parseInt(unidade.valor, 10).toLocaleString("pt-BR")}
                        </span>
                        ,00
                      </p>
                    </div>

                    <div>
                      <img
                        src={unidade.logo.sizes.large}
                        alt={unidade.logo.title}
                      />
                      <BgButton
                        to={`/unidade/${unidade.slug}`}
                        width="190px"
                        bgColor="#6abd45"
                        bgColorHover="#84d660"
                      >
                        Saiba Mais
                      </BgButton>
                    </div>
                  </UnidadeMobile>
                ))}
              </Unidades>
              {total !== 0 && (
                <ReactPaginate
                  pageCount={totalPages}
                  pageRangeDisplayed={5}
                  marginPagesDisplayed={2}
                  forcePage={currentPage}
                  onPageChange={page => pageChangeHandle(page)}
                  containerClassName="pagination__container"
                  previousLabel="Anterior"
                  nextLabel="Próximo"
                />
              )}
            </ContainerMobile>
          </Mobile>
        </>
      ) : (
        <div style={{ width: "100%", height: "500px", background: "#f3f3f3" }}>
          <Loading />
        </div>
      )}
      {/* Unidades Fim */}

      <Simulador />
      <Footer />
    </>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(SearchActions, dispatch);

const mapStateToProps = state => ({
  data: state.search.data,
  params: state.search.queryParams,
  loading: state.search.loading,
  total: state.search.total,
  totalPages: state.search.totalPages,
  currentPage: state.search.currentPage
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);
