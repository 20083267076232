/* eslint-disable no-extra-boolean-cast */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Responsive from 'react-responsive';
import ContentLoader from 'react-content-loader';
import queryString from 'query-string';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UnidadesActions from '../../store/ducks/unidades';

// Style Components
// Não aguento mais
import {
  Banner,
  BannerTablet,
  BannerMobile,
  Destaque,
  EmpDestaque,
  Square,
  EmpInfos,
  EmpImages,
  Relevantes,
  RelevantesTablet,
  RelevantesMobile,
  Comparador,
  ComparadorMobile,
} from './styles';

// Components
import Header from '../../components/Header';
import Filter from '../../components/Filter';
import ApartamentoSingle from '../../components/ApartamentoSingle';
import BgButton from '../../components/BgButton';
import Simulador from '../../components/Simulador';
import Footer from '../../components/Footer';
import smoothScroll from '../../routes/smoothScroll';

// Assets
import MiquelaoFachada from '../../assets/images/miquelao-fachada.jpg';
import BeneventoFachada from '../../assets/images/benevento-fachada.jpg';

function Main({
  getUnidadesRequest, categorias, getSearchRequest, params,
}) {
  const Desktop = props => <Responsive {...props} minWidth={992} />;
  const Tablet = props => (
    <Responsive {...props} minWidth={768} maxWidth={991} />
  );
  const Mobile = props => <Responsive {...props} maxWidth={767} />;
  const [numero, setNumero] = useState(2);
  const totalUnidadesProntos = !!categorias.prontos && categorias.prontos.length;
  const totalUnidadesPlanta = !!categorias.planta && categorias.planta.length;

  // GetUnidades
  useEffect(() => {
    document.title = 'Vendas de apartamentos - Construtora Miquelão';
    getUnidadesRequest();
  }, [getUnidadesRequest]);

  function scrollTo(element) {
    smoothScroll(element);
  }

  function handleVerMais() {
    setNumero(numero + 2);
    scrollTo('#novos');
  }

  console.log(totalUnidadesPlanta);

  return (
    <>
      <Header />

      {/* Banner Inicio */}
      <Desktop>
        <Banner>
          <h2>Temos um apê miquelão perfeito pra você!</h2>
          <Filter />
        </Banner>
      </Desktop>

      <Tablet>
        <BannerTablet>
          <h2>Temos um apê miquelão perfeito pra você!</h2>
        </BannerTablet>
        <Filter />
      </Tablet>

      <Mobile>
        <BannerMobile>
          <h2>Temos um apê miquelão perfeito pra você!</h2>
        </BannerMobile>
        <Filter />
      </Mobile>
      {/* Banner Fim */}

      {/* Destaque Inicio */}
      <Desktop>
        {!!categorias.lancamentos && categorias.lancamentos.length > 0 && (
          <Destaque>
            <h3>
              Onde tem Miquelão,
              {' '}
              <span>tem qualidade</span>
            </h3>
            <EmpDestaque>
              <Square>
                <span>Lançamentos</span>
              </Square>
              <img
                src={categorias.lancamentos[0].fachada.sizes.large}
                alt={categorias.lancamentos[0].fachada.title}
              />
              <EmpInfos>
                <h4>{categorias.lancamentos[0].title}</h4>
                <p>
                  {!!categorias.lancamentos[0].lazer
                    && `${categorias.lancamentos[0].lazer.substring(0, 70)}...`}
                </p>
                <p>Apartamentos a partir de:</p>
                <span>
                  R$
                  {' '}
                  <b>
                    {parseInt(
                      categorias.lancamentos[0].valor,
                      10,
                    ).toLocaleString('pt-BR')}
                    <span>,00</span>
                  </b>
                </span>
                <BgButton
                  to={`/unidade/${categorias.lancamentos[0].slug}`}
                  width="190px"
                  bgColor="#6abd45"
                  bgColorHover="#84d660"
                >
                  Saiba Mais
                </BgButton>
              </EmpInfos>
              <EmpImages>
                {!!categorias.lancamentos[0].galeria_unidade ? (
                  <>
                    <img
                      src={
                        categorias.lancamentos[0].galeria_unidade[0].sizes.large
                      }
                      alt={categorias.lancamentos[0].galeria_unidade[0].title}
                    />
                    <img
                      src={
                        categorias.lancamentos[0].galeria_unidade[1].sizes.large
                      }
                      alt={categorias.lancamentos[0].galeria_unidade[1].title}
                    />
                  </>
                ) : (
                  <div style={{ minWidth: '250px' }} />
                )}
              </EmpImages>
            </EmpDestaque>
          </Destaque>
        )}
        {!!categorias.lancamentos ? null : (
          <div
            style={{ background: '#f3f3f3', height: '550px', width: '100%' }}
          />
        )}
      </Desktop>
      {/* Destaque Fim */}

      {/* Relevantes Inicio */}
      <Desktop>
        <Relevantes>
          <div>
            <h4>Na planta</h4>
            {totalUnidadesPlanta === 0 && (
              <p className="erro">Nenhuma unidade encontrada</p>
            )}
            {!!categorias.planta ? (
              categorias.planta.map((unidade, index) => {
                if (index < 2) {
                  return (
                    <ApartamentoSingle key={unidade.id} unidade={unidade} />
                  );
                }
                return null;
              })
            ) : (
              <div
                style={{
                  height: '600px',
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '65px',
                  justifyContent: 'space-between',
                }}
              >
                <ContentLoader
                  height={280}
                  width={530}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="5" ry="5" width="200" height="280" />
                  <rect x="220" y="10" rx="4" ry="4" width="295" height="13" />
                  <rect x="220" y="30" rx="4" ry="4" width="257" height="13" />
                  <rect x="220" y="60" rx="4" ry="4" width="295" height="5" />
                  <rect x="220" y="75" rx="4" ry="4" width="295" height="5" />
                  <rect x="220" y="90" rx="4" ry="4" width="270" height="5" />
                  <rect x="220" y="220" rx="4" ry="4" width="255" height="25" />
                </ContentLoader>
                <ContentLoader
                  height={280}
                  width={530}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="5" ry="5" width="200" height="280" />
                  <rect x="220" y="10" rx="4" ry="4" width="295" height="13" />
                  <rect x="220" y="30" rx="4" ry="4" width="257" height="13" />
                  <rect x="220" y="60" rx="4" ry="4" width="295" height="5" />
                  <rect x="220" y="75" rx="4" ry="4" width="295" height="5" />
                  <rect x="220" y="90" rx="4" ry="4" width="270" height="5" />
                  <rect x="220" y="220" rx="4" ry="4" width="255" height="25" />
                </ContentLoader>
              </div>
            )}
          </div>
          <div>
            <h4>Prontos para morar</h4>
            {!!categorias.prontos ? (
              categorias.prontos.map((unidade, index) => {
                if (index < 2) {
                  return (
                    <ApartamentoSingle key={unidade.id} unidade={unidade} />
                  );
                }
                return null;
              })
            ) : (
              <div
                style={{
                  height: '600px',
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '65px',
                  justifyContent: 'space-between',
                }}
              >
                <ContentLoader
                  height={280}
                  width={530}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="5" ry="5" width="200" height="280" />
                  <rect x="220" y="10" rx="4" ry="4" width="295" height="13" />
                  <rect x="220" y="30" rx="4" ry="4" width="257" height="13" />
                  <rect x="220" y="60" rx="4" ry="4" width="295" height="5" />
                  <rect x="220" y="75" rx="4" ry="4" width="295" height="5" />
                  <rect x="220" y="90" rx="4" ry="4" width="270" height="5" />
                  <rect x="220" y="220" rx="4" ry="4" width="255" height="25" />
                </ContentLoader>
                <ContentLoader
                  height={280}
                  width={530}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="5" ry="5" width="200" height="280" />
                  <rect x="220" y="10" rx="4" ry="4" width="295" height="13" />
                  <rect x="220" y="30" rx="4" ry="4" width="257" height="13" />
                  <rect x="220" y="60" rx="4" ry="4" width="295" height="5" />
                  <rect x="220" y="75" rx="4" ry="4" width="295" height="5" />
                  <rect x="220" y="90" rx="4" ry="4" width="270" height="5" />
                  <rect x="220" y="220" rx="4" ry="4" width="255" height="25" />
                </ContentLoader>
              </div>
            )}
          </div>
        </Relevantes>
      </Desktop>

      <Tablet>
        <RelevantesTablet>
          <div>
            <h4>Na planta</h4>
            {totalUnidadesPlanta === 0 && (
              <p className="erro">Nenhuma unidade encontrada</p>
            )}
            {!!categorias.planta ? (
              categorias.planta.map((unidade, index) => {
                if (index < 2) {
                  return (
                    <ApartamentoSingle key={unidade.id} unidade={unidade} />
                  );
                }
                return null;
              })
            ) : (
              <div
                style={{
                  marginTop: '65px',
                }}
              >
                <ContentLoader
                  height={280}
                  width={530}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="5" ry="5" width="200" height="280" />
                  <rect x="220" y="10" rx="4" ry="4" width="295" height="13" />
                  <rect x="220" y="30" rx="4" ry="4" width="257" height="13" />
                  <rect x="220" y="60" rx="4" ry="4" width="295" height="5" />
                  <rect x="220" y="75" rx="4" ry="4" width="295" height="5" />
                  <rect x="220" y="90" rx="4" ry="4" width="270" height="5" />
                  <rect x="220" y="220" rx="4" ry="4" width="255" height="25" />
                </ContentLoader>
              </div>
            )}
          </div>
          <div>
            <h4>Prontos para morar</h4>
            {!!categorias.prontos ? (
              categorias.prontos.map((unidade, index) => {
                if (index < 2) {
                  return (
                    <ApartamentoSingle key={unidade.id} unidade={unidade} />
                  );
                }
                return null;
              })
            ) : (
              <div
                style={{
                  marginTop: '65px',
                }}
              >
                <ContentLoader
                  height={280}
                  width={530}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="5" ry="5" width="200" height="280" />
                  <rect x="220" y="10" rx="4" ry="4" width="295" height="13" />
                  <rect x="220" y="30" rx="4" ry="4" width="257" height="13" />
                  <rect x="220" y="60" rx="4" ry="4" width="295" height="5" />
                  <rect x="220" y="75" rx="4" ry="4" width="295" height="5" />
                  <rect x="220" y="90" rx="4" ry="4" width="270" height="5" />
                  <rect x="220" y="220" rx="4" ry="4" width="255" height="25" />
                </ContentLoader>
              </div>
            )}
          </div>
        </RelevantesTablet>
      </Tablet>

      <Mobile>
        <RelevantesMobile>
          <div>
            <h4>Na planta</h4>
            {totalUnidadesPlanta === 0 && (
              <p className="erro">Nenhuma unidade encontrada</p>
            )}
            {!!categorias.planta ? (
              categorias.planta.map((unidade, index) => {
                if (index < 2) {
                  return (
                    <ApartamentoSingle
                      id={index}
                      key={unidade.id}
                      unidade={unidade}
                    />
                  );
                }
                return null;
              })
            ) : (
              <div style={{ marginTop: '40px' }}>
                <ContentLoader
                  height={575}
                  width={300}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="5" ry="5" width="300" height="280" />
                  <rect x="0" y="320" rx="4" ry="4" width="300" height="13" />
                  <rect x="0" y="335" rx="4" ry="4" width="257" height="13" />
                  <rect x="0" y="370" rx="4" ry="4" width="300" height="5" />
                  <rect x="0" y="380" rx="4" ry="4" width="300" height="5" />
                  <rect x="0" y="390" rx="4" ry="4" width="270" height="5" />
                  <rect x="0" y="550" rx="4" ry="4" width="300" height="25" />
                </ContentLoader>
              </div>
            )}
          </div>
          <div id="novos">
            <h4>Prontos para morar</h4>
            {!!categorias.prontos ? (
              categorias.prontos.map((unidade, index) => {
                if (index < numero) {
                  return (
                    <ApartamentoSingle key={unidade.id} unidade={unidade} />
                  );
                }
                return null;
              })
            ) : (
              <div style={{ marginTop: '40px' }}>
                <ContentLoader
                  height={575}
                  width={300}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="5" ry="5" width="300" height="280" />
                  <rect x="0" y="320" rx="4" ry="4" width="300" height="13" />
                  <rect x="0" y="335" rx="4" ry="4" width="257" height="13" />
                  <rect x="0" y="370" rx="4" ry="4" width="300" height="5" />
                  <rect x="0" y="380" rx="4" ry="4" width="300" height="5" />
                  <rect x="0" y="390" rx="4" ry="4" width="270" height="5" />
                  <rect x="0" y="550" rx="4" ry="4" width="300" height="25" />
                </ContentLoader>
              </div>
            )}
            {numero < totalUnidadesProntos ? (
              <button className="vermais" onClick={handleVerMais}>
                VER MAIS
              </button>
            ) : (
              ''
            )}
          </div>
        </RelevantesMobile>
      </Mobile>
      {/* Relevantes Fim */}

      <Simulador />

      {/* Comparador inicio */}
      <Desktop>
        <Comparador>
          <div>
            <h3>Está em dúvida entre dois ou mais empreendimentos?</h3>
            <p>Compare-os e veja qual mais lhe agrada.</p>
            <BgButton
              to="/comparar"
              width="320px"
              bgColor="#33499c"
              bgColorHover="#3b55b6"
            >
              COMPARAR AGORA
            </BgButton>
          </div>
          <div>
            <img src={MiquelaoFachada} alt="Miquelao" />
            <img src={BeneventoFachada} alt="Benevento" />
          </div>
        </Comparador>
      </Desktop>

      <Tablet>
        <Comparador>
          <div>
            <h3>Está em dúvida entre dois ou mais empreendimentos?</h3>
            <p>Compare-os e veja qual mais lhe agrada.</p>
            <BgButton
              to="/comparar"
              width="320px"
              bgColor="#33499c"
              bgColorHover="#3b55b6"
            >
              COMPARAR EMPREENDIMENTOS
            </BgButton>
          </div>
          <div>
            <img src={MiquelaoFachada} alt="Miquelao" />
            <img src={BeneventoFachada} alt="Benevento" />
          </div>
        </Comparador>
      </Tablet>

      <Mobile>
        <ComparadorMobile>
          <div>
            <h3>Está em dúvida entre dois ou mais empreendimentos?</h3>
            <p>Compare-os e veja qual mais lhe agrada.</p>
            <BgButton
              to="/comparar"
              width="240px"
              bgColor="#33499c"
              bgColorHover="#3b55b6"
            >
              COMPARAR EMPREENDIMENTOS
            </BgButton>
          </div>
        </ComparadorMobile>
      </Mobile>
      {/* Comparador fim */}

      <Footer />
    </>
  );
}

Main.propTypes = {
  getUnidadesRequest: PropTypes.func.isRequired,
  // unidades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  categorias: PropTypes.shape({
    lancamentos: PropTypes.array,
    prontos: PropTypes.array,
    planta: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = state => ({
  unidades: state.unidades.unidades,
  categorias: state.unidades.categorias,
});

const mapDispatchToProps = dispatch => bindActionCreators(UnidadesActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Main);
