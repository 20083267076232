import React from 'react';

import MaskedInput from 'react-text-mask';
// import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

function FormattedInputs(props) {
  const {
    width,
    label,
    value,
    handleChange,
    withMask,
    mask,
    placeholder,
    error,
    errorMessage,
  } = props;

  if (withMask) {
    return (
      <>
        <TextField
          style={{ width: width ? `${width}px` : '100%' }}
          label={label}
          value={value}
          onBlur={handleChange}
          id={`formatted-text-mask-input-${label}`}
          placeholder={placeholder}
          error={!!error}
          InputProps={{
            inputComponent: ({ inputRef, ...other }) => (
              <MaskedInput
                ref={(ref) => {
                  inputRef(ref ? ref.inputElement : null);
                }}
                mask={mask}
                placeholderChar={'\u2000'}
                {...other}
              />
            ),
          }}
        />
        {!!errorMessage && (
          <div
            style={{
              position: 'absolute',
              bottom: '-45px',
              fontSize: '13px',
              color: '#f44336',
            }}
          >
            {errorMessage}
          </div>
        )}
      </>
    );
  }
  return (
    <TextField
      style={{ width: width ? `${width}px` : '100%' }}
      label={label}
      name={label}
      value={value}
      onChange={handleChange}
      id={`input-${label}`}
      {...props}
    />
  );
}

export default FormattedInputs;
