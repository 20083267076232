import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  setCompare: ['unidade'],
  removeCompare: ['index'],
  getEmpreendimentosRequest: null,
  getEmpreendimentosSuccess: ['data'],
  filterUnidadesRequest: ['empId', 'pavimento'],
  filterUnidadesSuccess: ['data'],
});

export const ComparadorTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  unidades: JSON.parse(localStorage.getItem('@VendasMiquelao:comparador')) || [],
  empreendimentos: [],
  filtredUnidades: [],
});

/* Reducers */

export const addUnidade = (state, { unidade }) => {
  // Fila FIFO para estrutura de comparação
  const fifo = [...state.unidades];
  if (fifo.length < 3) {
    fifo.push(unidade);
    localStorage.setItem('@VendasMiquelao:comparador', JSON.stringify(fifo));
    return state.merge({ unidades: fifo });
  }
  fifo.shift();
  fifo.push(unidade);
  localStorage.setItem('@VendasMiquelao:comparador', JSON.stringify(fifo));
  return state.merge({ unidades: fifo });
};

export const removeUnidade = (state, { index }) => {
  const unidades = [...state.unidades];
  unidades.splice(index, 1);
  localStorage.setItem('@VendasMiquelao:comparador', JSON.stringify(unidades));
  return state.merge({ unidades });
};

export const setEmpreendimentos = (state, { data }) => state.merge({ empreendimentos: data });

export const setFiltredUnidades = (state, { data }) => state.merge({ filtredUnidades: data });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_COMPARE]: addUnidade,
  [Types.REMOVE_COMPARE]: removeUnidade,
  [Types.GET_EMPREENDIMENTOS_SUCCESS]: setEmpreendimentos,
  [Types.FILTER_UNIDADES_SUCCESS]: setFiltredUnidades,
});
