import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-gap: 40px;
  margin: 60px 0;
  transition: all ease-in-out 0.2s;

  &:hover {
    transform: scale(1.01);
  }

  > div {
    > img {
      width: 100%;
      height: 280px;
      object-fit: cover;
    }

    h5 {
      font-family: 'Noirden';
      font-size: 27px;
      font-weight: normal;
      letter-spacing: 1px;
      color: #4a4a4a;
      margin-bottom: 15px;
    }

    p {
      font-size: 15px;
      font-weight: normal;
      color: #4a4a4a;

      &:first-of-type {
        margin-bottom: 15px;
      }
      &:last-of-type {
        margin-bottom: 20px;
      }
    }

    > span {
      font-size: 15px;
      font-weight: normal;
      color: #4a4a4a;

      b {
        font-size: 30px;

        span {
          font-size: 15px;
        }
      }
    }

    > div {
      margin-top: 40px;
    }
  }
`;

export const ContainerTablet = styled(Container)``;

export const ContainerMobile = styled(Container)`
  grid-template-columns: 1fr;

  > div {
    text-align: center;

    > div {
      margin: 40px auto 0 auto;
    }
  }
`;
