import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { reducer as pages } from './pages';
import { reducer as unidades } from './unidades';
import { reducer as comparador } from './comparador';
import { reducer as search } from './search';

export default history => combineReducers({
  pages,
  unidades,
  comparador,
  search,
  router: connectRouter(history),
});
