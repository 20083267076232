import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  z-index: 999999;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999999;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  z-index: 99999999999;

  @media (max-width: 480px) {
    width: 95%;

    img {
      width: 100%;
    }
  }

  button {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: rgba(255, 255, 255, 0.4);
    border: none;
    border-radius: 50%;
    cursor: pointer;
  }
`;
