import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import api from '../../services/api';

import SearchActions from '../ducks/search';

export function* getSearchResults({ queryParams }) {
  yield put(push('/busca'));

  try {
    const response = yield call(api.get, `/busca?${queryParams}`);
    const total = response.headers['x-wp-total'];
    const totalPages = response.headers['x-wp-totalpages'];

    const { page } = queryString.parse(queryParams);
    if (page) {
      yield put(SearchActions.getSearchSuccess(response.data, total, totalPages, page));
    } else {
      yield put(SearchActions.getSearchSuccess(response.data, total, totalPages, 1));
    }
  } catch (err) {
    yield put(SearchActions.getSearchFailure());
  }
}
