/* eslint-disable no-extra-boolean-cast */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import Responsive from "react-responsive";
import ademail from "../../services/ademail";
import { toast } from "react-toastify";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UnidadesActions from "../../store/ducks/unidades";

import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import Radio from "@material-ui/core/Radio";
import SmoothScroll from "smooth-scroll";

import {
  Container,
  Filtro,
  FiltroMobile,
  Resultado,
  ResultadoMobile,
  Financiamento,
  FinanciamentoMobile,
  Contato,
  ContatoMobile
} from "./styles";

// Components
import ReactSelectUi from "../ReactSelectUi";
import BgButton from "../BgButton";
import InputUi from "../InputUi";

// Assets
import ArrowIcon from "../../assets/images/arrow-icon.svg";
import CaixaLogo from "../../assets/images/caixa-logo.png";
import BBLogo from "../../assets/images/bb-logo.png";
import SantanderLogo from "../../assets/images/santander-logo.png";

function Simulador({ getUnidadesRequest, unidades, unidadeShownContato }) {
  const [unidade, setUnidade] = useState({ value: "" });
  const [entrada, setEntrada] = useState({ value: "" });
  const [radio, setRadio] = useState("sim");
  const [balao, setBalao] = useState({ value: "" });
  const [resultado, setResultado] = useState([]);
  const [isResultadoShown, setIsResultadoShown] = useState(false);
  const [isContatoShown, setIsContatoShown] = useState(false);
  const [error, setError] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [mensagem, setMensagem] = useState("");

  const Desktop = props => <Responsive {...props} minWidth={992} />;
  const Mobile = props => <Responsive {...props} maxWidth={991} />;

  // Get todas unidades
  useEffect(() => {
    getUnidadesRequest();
  }, [getUnidadesRequest]);

  useEffect(() => {
    if (unidadeShownContato) {
      const scroll = new SmoothScroll();
      setIsContatoShown(true);
      setIsResultadoShown(false);
      setTimeout(() => {
        const el = document.getElementById("contato");
        scroll.animateScroll(el);
        scroll.destroy();
      }, 300);
    }
  }, [unidadeShownContato]);

  const numberMask = createNumberMask({
    prefix: "R$ ",
    suffix: ",00",
    thousandsSeparatorSymbol: "."
  });

  function handleResultado() {
    let valor = null;
    let valorBalao = null;
    setBalao({ value: document.getElementById("balao__input").value });
    // Validations
    if (!unidade.value) {
      setError("Selecione um apartamento");
      return;
    }
    if (!entrada.value) {
      valor = 0.0;
      setEntrada({ value: 0 });
    } else {
      valor = entrada.value
        .replace("R$", "")
        .split(".")
        .join("")
        .replace(",", ".");
    }

    const unidadeValor = unidade.value.value.replace(".", "");
    if (parseInt(valor, 10) < unidadeValor * 0.2) {
      setError(
        "Valor de entrada não pode ser menor que 20% do valor da unidade"
      );
      return;
    }

    if (parseInt(valor, 10) > unidadeValor) {
      setError("Valor de entrada** não pode ser maior que o valor do imóvel");
      return;
    }

    if (radio === "sim") {
      if (!document.getElementById("balao__input").value) {
        setError("Informe o valor do balão");
        return;
      }

      valorBalao = document
        .getElementById("balao__input")
        .value.replace("R$", "")
        .split(".")
        .join("")
        .replace(",", ".");
    } else {
      valorBalao = 0;
    }
    setError("");

    // Gerar array com resultado
    const opcoes = [12, 24, 36, 48, 60];

    const naPlantaOpcoes = [
      { parcelas: 12, coeficiente: 0.08552 },
      { parcelas: 24, coeficiente: 0.04596 },
      { parcelas: 36, coeficiente: 0.03379 },
      { parcelas: 48, coeficiente: 0.02753 },
      { parcelas: 60, coeficiente: 0.02348 }
    ];

    const prontoMorarOpcoes = [
      { parcelas: 12, coeficiente: 0.08998 },
      { parcelas: 24, coeficiente: 0.04821 },
      { parcelas: 36, coeficiente: 0.03438 },
      { parcelas: 48, coeficiente: 0.02753 },
      { parcelas: 60, coeficiente: 0.02348 }
    ];

    if (unidade.value.tipo === "na_planta") {
      const result = naPlantaOpcoes.map(option => ({
        parcelas: option.parcelas,
        entrada: !!entrada.value
          ? entrada.value
          : valor.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL"
            }),
        balao: !!document.getElementById("balao__input").value
          ? document.getElementById("balao__input").value
          : valorBalao.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL"
            }),
        parcela:
          (parseInt(unidadeValor, 10) -
            parseInt(valor, 10) -
            (option.parcelas / 12) * valorBalao) *
          option.coeficiente
      }));
      setResultado(result);
    } else {
      const result = prontoMorarOpcoes.map(option => ({
        parcelas: option.parcelas,
        entrada: !!entrada.value
          ? entrada.value
          : valor.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL"
            }),
        balao: !!document.getElementById("balao__input").value
          ? document.getElementById("balao__input").value
          : valorBalao.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL"
            }),
        parcela:
          (parseInt(unidadeValor, 10) -
            parseInt(valor, 10) -
            (option.parcelas / 12) * valorBalao) *
          option.coeficiente
      }));
      setResultado(result);
    }

    const scroll = new SmoothScroll();
    setIsContatoShown(false);
    setIsResultadoShown(true);
    setTimeout(() => {
      const el = document.getElementById("resultado");
      scroll.animateScroll(el);
      scroll.destroy();
    }, 300);
  }

  function handleContato() {
    const scroll = new SmoothScroll();
    setIsResultadoShown(false);
    setIsContatoShown(true);
    setTimeout(() => {
      const el = document.getElementById("contato");
      scroll.animateScroll(el);
      scroll.destroy();
    }, 300);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const body = `<p>Nome: ${document.getElementById("form__nome").value}</p>
        <p>Email: ${document.getElementById("form__email").value}</p>
        <p>Telefone: ${
          document.getElementById("formatted-text-mask-input-TELEFONE").value
        }</p>
        ${
          !!unidade && !!unidade.value
            ? `<p>Unidade simulada: ${unidade.value.label}</p>`
            : ""
        }
        <p>Mensagem: ${document.getElementById("form__mensagem").value}</p>`;

    const mail = {
      to: "anarubia@miquelao.com.br",
      from: document.getElementById("form__email").value,
      as: document.getElementById("form__nome").value,
      cc: JSON.stringify(["fabio@miquelao.com.br"]),
      bcc: JSON.stringify(["web@agenciaade.com.br"]),
      subject: "Novo contato via formulário do site - Vendas miqueão",
      message: body
    };

    function clearForm() {
      document.getElementById("form__nome").value = "";
      document.getElementById("form__email").value = "";
      document.getElementById("formatted-text-mask-input-TELEFONE").value = "";
      document.getElementById("form__mensagem").value = "";
    }

    try {
      const response = await ademail.post("/email", mail);
      toast.success("🚀 Email enviado com sucesso");
      clearForm();
    } catch (err) {
      toast.error(
        "😣😣😣 Erro ao enviar mensagem. Verifique os campos e tente novamente."
      );
      clearForm();
    }
  }

  return (
    <Container>
      {/* Filtro Inicio */}
      <Desktop>
        <Filtro>
          <h2>Simular Financiamento</h2>
          <p>
            Simule um financiamente e veja qual é a melhor opção para você
            adquirir um apê Miquelão!
          </p>
          <form>
            <div className="apartamento">
              <span>Selecione um apartamento</span>
              {!!unidades && (
                <ReactSelectUi
                  placeholder="Ex: Unidade 84 - R$ 752.000,00 - Orfãs - Palazzo Ducale"
                  data={[...unidades]}
                  value={unidade.value}
                  setValue={value => setUnidade({ value, error: "" })}
                />
              )}
            </div>
            <div className="opcoes">
              <div>
                <span>Valor de entrada: </span>
                <MaskedInput
                  mask={numberMask}
                  className="valor__input"
                  type="text"
                  value={entrada.value}
                  onBlur={e => {
                    setEntrada({ value: e.target.value, error: "" });
                  }}
                  placeholder="R$ 100.000,00"
                />
                <span className="info">
                  * Em empreendimentos prontos ou com entrega em até 20 meses a
                  entrada mínima é de 20%
                </span>
              </div>
              <div>
                <span>Balão: </span>
                <div>
                  <Radio
                    checked={radio === "sim"}
                    onChange={() => setRadio("sim")}
                    value="Sim"
                    name="balao"
                    aria-label="Sim"
                    classes={{
                      root: "radioRoot",
                      checked: "radioChecked"
                    }}
                  />
                  <span>Sim</span>
                  <Radio
                    checked={radio === "nao"}
                    onChange={() => {
                      setRadio("nao");
                      setBalao({ value: null, error: "" });
                    }}
                    value="Nao"
                    name="balao"
                    aria-label="Nao"
                    classes={{
                      root: "radioRoot",
                      checked: "radioChecked"
                    }}
                  />
                  <span>Não</span>
                </div>
              </div>
              <div>
                <span>Balão anual: </span>
                <MaskedInput
                  id="balao__input"
                  mask={numberMask}
                  className={`balao__input ${!!balao.error ? "errored" : null}`}
                  type="text"
                  value={balao.value}
                  disabled={radio === "nao"}
                  placeholder="R$ 25.000,00"
                />
              </div>
            </div>

            <div className="submit">
              <BgButton
                handleClick={() => handleResultado()}
                width="140px"
                bgColor="#6abd45"
                bgColorHover="#84d660"
              >
                Simular
              </BgButton>
            </div>
            {!!error && <div className="form__error">{error}</div>}
          </form>

          {/* eslint-disable-next-line react/button-has-type */}
          <button onClick={() => handleContato()}>
            <div>
              <span>
                Envie sua proposta para a gente
                <img src={ArrowIcon} alt="Seta" />
              </span>
            </div>
          </button>
        </Filtro>
      </Desktop>

      <Mobile>
        <FiltroMobile>
          <h2>Simular Financiamento</h2>
          <p>
            Simule um financiamente e veja qual é a melhor opção para você
            adquirir um Miquelão!
          </p>
          <form>
            <div className="apartamento">
              <span>Selecione um apartamento</span>
              {!!unidades && (
                <ReactSelectUi
                  data={[...unidades]}
                  value={unidade.value}
                  setValue={value => setUnidade({ value, error: "" })}
                  placeholder="Apartamento com 2 quartos..."
                />
              )}
            </div>
            <div className="opcoes">
              <div>
                <span>Valor de entrada: </span>
                <MaskedInput
                  mask={numberMask}
                  className="valor__input"
                  type="text"
                  value={entrada.value}
                  onBlur={e => {
                    setEntrada({ value: e.target.value, error: "" });
                  }}
                  placeholder="R$ 100.000,00"
                />
                <span className="info">
                  * Em empreendimentos prontos ou com entrega em até 20 meses a
                  entrada mínima é de 20%
                </span>
              </div>
              <div>
                <span>Balão: </span>
                <div>
                  <Radio
                    checked={radio === "sim"}
                    onChange={() => setRadio("sim")}
                    value="Sim"
                    name="balao"
                    aria-label="Sim"
                    classes={{
                      root: "radioRoot",
                      checked: "radioChecked"
                    }}
                  />
                  <span>Sim</span>
                  <Radio
                    checked={radio === "nao"}
                    onChange={() => {
                      setRadio("nao");
                      setBalao({ value: null, error: "" });
                    }}
                    value="Nao"
                    name="balao"
                    aria-label="Nao"
                    classes={{
                      root: "radioRoot",
                      checked: "radioChecked"
                    }}
                  />
                  <span>Não</span>
                </div>
              </div>
              <div>
                <span>Balão anual: </span>
                <MaskedInput
                  id="balao__input"
                  mask={numberMask}
                  className="balao__input"
                  type="text"
                  value={balao.value}
                  onBlur={e => {
                    setBalao({ value: e.target.value, error: "" });
                  }}
                  disabled={radio === "nao"}
                  placeholder="R$ 25.000,00"
                />
              </div>
            </div>

            <div className="submit">
              <BgButton
                handleClick={() => handleResultado()}
                width="140px"
                bgColor="#6abd45"
                bgColorHover="#84d660"
              >
                Simular
              </BgButton>
            </div>
          </form>

          {/* eslint-disable-next-line react/button-has-type */}
          <button onClick={() => handleContato()}>
            <div>
              <span>
                Envie sua proposta para a gente
                <img src={ArrowIcon} alt="Seta" />
              </span>
            </div>
          </button>
        </FiltroMobile>
      </Mobile>
      {/* Filtro fim */}

      {/* Resultado inicio */}
      <Desktop>
        <Resultado
          id="resultado"
          style={{ display: !isResultadoShown && "none" }}
        >
          <h2>Veja abaixo as opções de financiamento encontradas para você</h2>
          <h3>{unidade && unidade.value && unidade.value.label}</h3>

          <Financiamento>
            <div className="financiamento__header">
              <span>OPÇÕES DE FINANCIAMENTO</span>
              <span>NÚMERO DE PARCELAS</span>
              <span>ENTRADA</span>
              <span>BALÃO</span>
              <span>VALOR DAS PARCELAS</span>
            </div>

            <div className="financiamento__opcoes">
              {resultado.map((item, index) => (
                <div key={Math.random()}>
                  <span>{`OPÇÃO ${index + 1}`}</span>
                  <span>{item.parcelas}</span>
                  <span>{item.entrada}</span>
                  <span style={{ textTransform: "uppercase" }}>{`${
                    item.balao
                  } / ANO`}</span>
                  <span>
                    {parseFloat(item.parcela).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL"
                    })}
                  </span>
                </div>
              ))}
            </div>
            <div className="financiamento__obs">
              <p>
                * O valor dos balões será atualizado a partir da data de
                assinatura do contrato, até a data de seu efetivo pagamento. A
                correção mensal será feita pelo INCC-M/FGV do fechamento do
                negócio até a entrega das chaves, e por (INCC-M + 1,0) % após a
                entrega das chaves.
              </p>
              <p>
                ** Os valores das parcelas já têm embutido uma correção
                estimada, podendo variar discretamente para mais ou para menos,
                conforme a variação da inflação do período, medida pelo
                INCC-M/FGV. O INCC-M considerado, para efeito de projeção neste
                cálculo, foi de 0,3% ao mês.
              </p>
            </div>
            <div className="financiamento__footer">
              <span>SE PREFERIR, VOCÊ PODE SIMULAR COM OS BANCOS:</span>
              <a
                href="https://habitacao.caixa.gov.br/siopiweb-web/simulaOperacaoInternet.do?method=inicializarCasoUso"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={CaixaLogo} alt="Caixa" />
              </a>
              <a
                href="https://www42.bb.com.br/portalbb/imobiliario/creditoimobiliario/simular,802,2250,2250.bbx"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={BBLogo} alt="Banco do Brasil" />
              </a>
              <a
                href="https://www.webcasas.com.br/webcasas/?headerandfooter/#/dados-pessoais"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={SantanderLogo} alt="Santander" />
              </a>
            </div>
          </Financiamento>
        </Resultado>
      </Desktop>

      <Mobile>
        <ResultadoMobile
          id="resultado"
          style={{ display: !isResultadoShown && "none" }}
        >
          <h2>Veja abaixo o plano ou os planos de financiamento encontrados</h2>
          <h3>{unidade && unidade.value && unidade.value.label}</h3>

          <FinanciamentoMobile>
            <div className="financiamento__header">
              <span>OPÇÕES DE FINANCIAMENTO</span>
              <span>NÚMERO DE PARCELAS</span>
              <span>ENTRADA</span>
              <span>BALÃO</span>
              <span>VALOR DAS PARCELAS</span>
            </div>

            <div className="financiamento__opcoes">
              {resultado.map((item, index) => (
                <div key={Math.random()}>
                  <span>{`OPÇÃO ${index + 1}`}</span>
                  <span>{item.parcelas}</span>
                  <span>{item.entrada}</span>
                  <span style={{ textTransform: "uppercase" }}>{`${
                    item.balao
                  } / ANO`}</span>
                  <span>
                    {parseFloat(item.parcela).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL"
                    })}
                  </span>
                </div>
              ))}
            </div>
          </FinanciamentoMobile>
          <div className="financiamento__obs">
            <p>
              * O valor dos balões será atualizado a partir da data de
              assinatura do contrato, até a data de seu efetivo pagamento. A
              correção mensal será feita pelo INCC-M/FGV do fechamento do
              negócio até a entrega das chaves, e por (INCC-M + 1,0) % após a
              entrega das chaves.
            </p>
            <p>
              ** Os valores das parcelas já têm embutido uma correção estimada,
              podendo variar discretamente para mais ou para menos, conforme a
              variação da inflação do período, medida pelo INCC-M/FGV. O INCC-M
              considerado, para efeito de projeção neste cálculo, foi de 0,3% ao
              mês.
            </p>
          </div>
          <div className="financiamento__footer">
            <span>SE PREFERIR, VOCÊ PODE SIMULAR COM OS BANCOS:</span>
            <a
              href="https://habitacao.caixa.gov.br/siopiweb-web/simulaOperacaoInternet.do?method=inicializarCasoUso"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={CaixaLogo} alt="Caixa" />
            </a>
            <a
              href="https://www42.bb.com.br/portalbb/imobiliario/creditoimobiliario/simular,802,2250,2250.bbx"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={BBLogo} alt="Banco do Brasil" />
            </a>
            <a
              href="https://www.webcasas.com.br/webcasas/?headerandfooter/#/dados-pessoais"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={SantanderLogo} alt="Santander" />
            </a>
          </div>
        </ResultadoMobile>
      </Mobile>
      {/* Resultado fim */}

      {/* Contato inicio */}
      <Desktop>
        <Contato id="contato" style={{ display: !isContatoShown && "none" }}>
          <h2>
            Deseja mais informações? Mande-nos um e-mail com a sua proposta que
            nós retornaremos para você
          </h2>
          <form onSubmit={handleSubmit}>
            <div>
              <InputUi id="form__nome" label="NOME" required />
              <InputUi id="form__email" label="EMAIL" required />
              <InputUi
                label="TELEFONE"
                required
                withMask
                mask={[
                  "(",
                  /[1-9]/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/
                ]}
              />
            </div>

            <InputUi
              id="form__mensagem"
              multiline
              rows={3}
              label="MENSAGEM"
              required
            />

            <div>
              <div className="infos">
                <span>
                  contato@
                  <span>miquelao</span>
                  .com.br
                </span>
                <span>
                  Rua Sete de Setembro, 800 | Centro | Ponta Grossa | PR | CEP:
                  84010-350
                </span>
                <span>42 3224-5121</span>
              </div>

              <button type="submit">
                Enviar
                <img src={ArrowIcon} alt="Seta" />
              </button>
            </div>
          </form>

          <div>
            <span>SE PREFERIR, VOCÊ PODE SIMULAR COM OS BANCOS:</span>
            <a href="/">
              <img src={CaixaLogo} alt="Caixa" />
            </a>
            <a href="/">
              <img src={BBLogo} alt="Banco do Brasil" />
            </a>
            <a href="/">
              <img src={SantanderLogo} alt="Santander" />
            </a>
          </div>
        </Contato>
      </Desktop>

      <Mobile>
        <ContatoMobile
          id="contato"
          style={{ display: !isContatoShown && "none" }}
        >
          <h2>
            Deseja mais informações? Mande-nos um e-mail com a sua proposta que
            nós retornaremos para você
          </h2>
          <form onSubmit={handleSubmit}>
            <div>
              <InputUi id="form__nome" label="NOME" required />
              <InputUi id="form__email" label="EMAIL" required />
              <InputUi
                label="TELEFONE"
                required
                withMask
                mask={[
                  "(",
                  /[1-9]/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/
                ]}
              />
            </div>

            <InputUi
              id="form__mensagem"
              multiline
              rows={3}
              label="MENSAGEM"
              required
            />

            <div>
              <div className="infos">
                <span>
                  contato@
                  <span>miquelao</span>
                  .com.br
                </span>
                <span>
                  Rua Sete de Setembro, 800 | Centro | Ponta Grossa | PR | CEP:
                  84010-350
                </span>
                <span>42 3224-5121</span>
              </div>

              <button type="submit">
                Enviar
                <img src={ArrowIcon} alt="Seta" />
              </button>
            </div>
          </form>

          <div>
            <span>SE PREFERIR, VOCÊ PODE SIMULAR COM OS BANCOS:</span>
            <a href="/">
              <img src={CaixaLogo} alt="Caixa" />
            </a>
            <a href="/">
              <img src={BBLogo} alt="Banco do Brasil" />
            </a>
            <a href="/">
              <img src={SantanderLogo} alt="Santander" />
            </a>
          </div>
        </ContatoMobile>
      </Mobile>
      {/* Contato fim */}
    </Container>
  );
}

const mapStateToProps = state => ({
  unidades: state.unidades.unidades
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(UnidadesActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Simulador);
