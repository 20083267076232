import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getUnidadesRequest: [],
  getUnidadesSuccess: ['unidades', 'categorias'],
});

export const UnidadesTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  unidades: null,
  categorias: {},
});

/* Reducers */

export const success = (state, { unidades, categorias }) => {
  return state.merge({ unidades, categorias });
};

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_UNIDADES_SUCCESS]: success,
});
