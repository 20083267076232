import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f3f3;
`;

export const FilterContainerMobile = styled(FilterContainer)`
  display: unset;
`;

export const Container = styled.div`
  padding: 65px 10% 140px 10%;
  background: #f3f3f3;

  h3 {
    font-family: 'Noirden';
    font-size: 40px;
    font-weight: normal;
    color: #4a4a4a;
    text-align: center;
  }

  .small {
    font-weight: 300;
    line-height: 40px;
    color: #4a4a4a;
    text-align: center;
  }

  .pagination__container {
    display: flex;
    width: 100%;
    max-width: 600px;
    align-items: center;
    list-style: none;
    margin-top: 40px;

    .disabled {
      cursor: not-allowed;
      opacity: 0.6;

      &:hover {
        border-color: #d9d9d9;
        color: #666;
        opacity: 0.6;
        transform: scale(1);
      }
    }

    .selected {
      background-color: rgb(132, 214, 96);
      border-color: rgb(132, 214, 96);
      color: #fff;

      &:hover {
        color: #fff;
      }
    }

    li {
      cursor: pointer;
      /* border-radius: 6px; */
      min-width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      list-style: none;
      float: left;
      /* border: 1px solid #d9d9d9; */
      background-color: #fff;
      margin-right: 8px;
      padding: 0 8px;
      color: #666;
      transition: all ease-in-out 0.2s;

      &:hover {
        border-color: rgb(132, 214, 96);
        color: rgb(132, 214, 96);
        transform: scale(1.1);
      }
    }
  }
`;

export const ContainerMobile = styled(Container)`
  h3 {
    text-align: center;
    margin-bottom: 10px;
  }

  small {
    text-align: center;
    line-height: 20px;
  }
`;

export const Unidades = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;

export const Unidade = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 340px;
  background: #fff;
  padding: 20px 30px;
  margin-top: 5px;
  transition: all ease-in-out 0.2s;

  &:hover {
    transform: scale(1.01);
  }

  > img {
    max-width: 200px;
    object-fit: cover;
  }

  > div:first-of-type {
    max-width: 500px;

    h4 {
      font-family: 'Noirden';
      font-size: 40px;
      font-weight: normal;
      color: #4a4a4a;
    }

    .unidade__nome {
      font-size: 23px;
      font-weight: bold;
      color: #4a4a4a;
      margin-top: 35px;
      margin-left: 12px;
    }

    .unidade__caracteristicas {
      font-size: 14px;
      font-weight: normal;
      color: #4a4a4a;
      margin-top: 10px;
      margin-left: 12px;
    }

    .unidade__descricao {
      font-size: 12px;
      font-weight: normal;
      color: #4a4a4a;
      margin-top: 25px;
      margin-left: 12px;
    }

    .status__valor {
      margin-top: 35px;
      display: flex;
      align-items: center;
    }

    .unidade__status {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 30px;
      font-size: 13px;
      font-weight: 300;
      color: #fff;
      text-transform: uppercase;
    }

    .unidade__valor {
      font-size: 15px;
      font-weight: normal;
      color: #6abd45;
      margin-left: 12px;

      span {
        font-size: 30px;
        font-weight: bold;
      }
    }
  }

  > div:last-of-type {
    display: flex;
    flex-direction: column;

    > img {
      width: 255px;
      object-fit: cover;
      margin-bottom: 65px;
    }
  }
`;

export const UnidadeMobile = styled(Unidade)`
  flex-direction: column;
  height: unset;

  > img {
    margin-top: 20px;
  }

  > div:first-of-type {
    h4 {
      text-align: center;
    }

    .unidade__valor {
      text-align: center;
      margin-left: unset;
    }
  }

  > div:last-of-type {
    padding: 20px 0;
  }
`;
