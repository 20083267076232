import styled from 'styled-components';

export const Container = styled.div`
  width: ${props => props.width};

  .root {
    width: 100%;
    font-family: 'Libre Franklin';
    font-size: 15px;

    &:focus {
      color: #6abd45;
    }

    &:after,
    &:before,
    &:hover {
      border-bottom-color: ${props => props.borderColor};
    }
  }

  .underline {
    &:after {
      border-bottom-color: #6abd45;
    }
  }

  .MuiInput-underline-16:hover:not(.MuiInput-disabled-15):not(.MuiInput-focused-14):not(.MuiInput-error-17):before {
    border-bottom-color: ${props => props.borderColor};
  }

  .MuiSelect-icon-11 {
    color: ${props => props.iconColor};
  }
`;

export const ErrorMessage = styled.div`
  position: absolute;
  bottom: -19px;
  font-size: 13px;
  color: #f44336;
`;
