import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getSearchRequest: ['queryParams'],
  getSearchSuccess: ['data', 'total', 'totalPages', 'page'],
  getSearchFailure: null,
});

export const SearchTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  data: [],
  total: 0,
  totalPages: 0,
  currentPage: 0,
  queryParams: '',
  loading: false,
});

/* Reducers */
export const setQueryParams = (state, { queryParams }) => state.merge({ queryParams, loading: true });

export const search = (state, {
  data, total, totalPages, page,
}) => state.merge({
  data,
  total,
  totalPages,
  currentPage: page - 1,
  loading: false,
});

export const failure = state => state.merge({
  data: [],
  loading: false,
  total: 0,
  totalPages: 0,
  currentPage: 0,
});

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_SEARCH_REQUEST]: setQueryParams,
  [Types.GET_SEARCH_SUCCESS]: search,
  [Types.GET_SEARCH_FAILURE]: failure,
});
