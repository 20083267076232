import styled from 'styled-components';

export const Container = styled.footer`
  height: 320px;
  background: #6abd45;
`;

export const Content = styled.div`
  height: 100%;
  width: 80%;
  max-width: 1240px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 300px 1fr;
  align-items: center;

  .infos {
    display: flex;
    justify-content: space-between;

    .institucional {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 160px;
      color: #fff;

      h3 {
        font-family: 'Noirden';
        font-size: 23px;
        margin-bottom: 20px;
      }

      a {
        font-size: 13px;
        text-decoration: none;
        color: #fff;
        transition: all ease-in-out 0.2s;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .contato {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 160px;
      color: #fff;
      font-size: 12px;

      h3 {
        font-family: 'Noirden';
        font-size: 23px;
        margin-bottom: 20px;
      }

      .endereco {
        display: flex;
        flex-direction: column;
      }

      > span span {
        font-weight: bold;
      }
    }
  }

  .logo {
    display: flex;
    align-items: flex-center;
    justify-content: center;
    width: 100%;
    height: 150px;
    object-fit: contain;
    object-position: center;
  }

  .calltoaction {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    color: #fff;

    > div {
      display: flex;
      flex-direction: column;

      h3 {
        font-family: 'Noirden';
        font-size: 31px;
        margin-bottom: 20px;
      }

      span {
        font-size: 12px;
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 768px) {
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;

    .infos {
      display: none;
    }

    .calltoaction {
      display: none;
    }
  }

  @media (max-width: 320px) {
  }
`;

export const Ade = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;

  span {
    font-size: 12px;
    color: #4a4a4a;
    cursor: pointer;
    img {
      height: 12px;
      margin: 0 5px;
      animation-name: heartbeat;
      animation-iteration-count: infinite;
      animation-duration: 2s;
    }
  }
`;
