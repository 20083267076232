import styled from 'styled-components';

export const Container = styled.section``;

export const Empreendimento = styled.div`
  width: 80%;
  max-width: 1240px;
  margin: auto;
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1.5fr 1fr;

  > div:first-of-type {
    padding: 50px 0;
    > img {
      max-width: 390px;
      max-height: 150px;
      object-fit: cover;
    }

    h2 {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
      color: #4d4d4d;
      margin-top: 75px;
    }

    p {
      font-size: 15px;
      font-weight: normal;
      color: #4a4a4a;
    }

    .empreedimento__descricao {
      width: 80%;
      margin-top: 12px;
      line-height: 20px;
    }

    .empreedimento__pre {
      text-transform: uppercase;
      margin-top: 65px;
    }

    .empreedimento__valor {
      font-size: 30px;
      color: #6abd45;
      margin-top: 25px;
      margin-bottom: 20px;

      span {
        font-size: 50px;
        font-weight: bold;
      }
    }

    .empreendimento__obs {
      font-size: 12px;
      font-weight: normal;
      color: #606060;
      margin-bottom: 65px;
    }
  }

  > div:last-of-type {
    position: relative;

    .square {
      position: absolute;
      top: 60px;
      left: -120px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 170px;
      height: 160px;
      background: #6abd45;

      span {
        width: 70%;
        font-family: 'Noirden';
        font-size: 30px;
        font-weight: normal;
        word-wrap: break-word;
        color: #fff;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const EmpreendimentoMobile = styled(Empreendimento)`
  width: 90%;
  grid-template-columns: 1fr;
  text-align: center;

  > div:first-of-type {
    padding: unset;

    > img {
      width: 100%;
      max-width: unset;
    }

    h2 {
      margin-top: 30px;
    }

    .empreedimento__descricao {
      width: 100%;
    }

    .empreedimento__pre {
      margin-top: 35px;
    }

    .empreedimento__valor {
      margin-bottom: 45px;
    }

    > div {
      margin: auto;
    }
  }

  > div:last-of-type {
    margin-top: 150px;

    .square {
      top: -120px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const Comparador = styled.div`
  background: #f3f3f3;
  margin: 30px 0;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 1240px;
    height: 280px;
    font-family: 'Noirden';
    text-align: center;
    margin: auto;

    > h3 {
      font-size: 40px;
      font-weight: bold;
      color: #6abd45;
    }

    > p {
      font-size: 22px;
      font-weight: normal;
      color: #6b6b6b;
      margin-top: 10px;
      margin-bottom: 35px;
    }
  }
`;

export const ComparadorMobile = styled(Comparador)`
  > div {
    width: 90%;
    height: unset;
    padding: 30px 0;

    h3 {
      font-size: 30px;
    }

    p {
      margin-top: 15px;
    }
  }
`;

export const Informacoes = styled.div`
  width: 80%;
  max-width: 1240px;
  margin: auto;
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  /* grid-gap: 20px; */

  > div {
    padding: 80px 0 80px 60px;
    margin-right: 20px;
    background: #f3f3f3;
  }

  > a img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .info {
    color: #4a4a4a;
    margin-top: 45px;

    &:first-of-type {
      margin: 0;
    }

    h3 {
      font-family: 'Noirden';
      font-size: 45px;
      font-weight: 200;
    }

    p {
      max-width: 500px;
      font-size: 15px;
      font-weight: normal;
      margin-top: 15px;
      line-height: 20px;
    }
  }

  .caracteristicas {
    display: grid;
    grid-template-columns: 100px 100px 100px 100px;
    grid-gap: 10px 10px;
    margin-top: 55px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      font-size: 12px;
      font-weight: normal;
      text-align: center;
      text-transform: uppercase;
      word-break: break-word;
      color: #4a4a4a;
      background: #fff;
      padding: 10%;
    }
  }

  .valor {
    display: flex;
    align-items: center;
    margin-top: 50px;

    span {
      font-size: 70px;
      font-weight: bold;
      color: #6abd45;
    }

    p {
      font-size: 15px;
      font-weight: normal;
      text-transform: uppercase;
      color: #4a4a4a;
      margin-left: 20px;
    }
  }
`;

export const InformacoesMobile = styled(Informacoes)`
  width: 100%;
  grid-template-columns: 100%;

  > div {
    margin-right: 0;
    padding: 60px 0;
    text-align: center;
  }

  .info {
    width: 90%;
    margin: 45px auto 0 auto;

    &:first-of-type {
      margin: auto;
    }
  }

  .caracteristicas {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    padding: 0 10px;

    div {
      width: 100%;
    }
  }

  .valor {
    flex-direction: column;
  }

  > img {
    grid-row-start: 1;
    height: 500px;
    object-fit: contain;
  }
`;

export const Mapa = styled.div`
  width: 100%;
  height: 490px;
  margin-top: 70px;

  .pin__icon {
    width: 45px;
    margin-top: -40px;
    margin-left: -15px;
  }
`;
