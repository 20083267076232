/* eslint-disable import/no-unresolved */
import styled from "styled-components";

export const Container = styled.div``;

export const Search = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 90px 90px;
  padding: 45px;
  margin-top: 50px;
  background: #fff;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    transition: all ease-in-out 0.2s;

    > button {
      width: 170px;
      height: 40px;
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;
      color: #fff;
      margin-left: 20px;
      background: #33499c;
      border: none;
      cursor: pointer;

      img {
        height: 8px;
        margin-left: 6px;
        transform: rotate(90deg);
        transition: all ease-in-out 0.2s;
      }
    }
  }

  .root {
    font-family: "Libre Franklin";
    font-size: 15px;

    &:focus {
      color: #6abd45;
    }

    &:after,
    &:before,
    &:hover {
      border-bottom-color: #4a4a4a;
    }
  }

  .underline {
    &:after {
      border-bottom-color: #6abd45;
    }
  }
`;

export const SearchMobile = styled(Search)`
  grid-template-columns: 1fr;
  grid-template-rows: unset;
  padding: 0 10% 40px 10%;

  > button {
    width: 180px;
    height: 30px;
    font-size: 13px;
    text-transform: uppercase;
    color: #fff;
    margin: 30px auto 0 auto;
    background: #6abd45;
    border: none;
    cursor: pointer;
    display: flex;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
    transition: all ease-in 0.2s;
    position: relative;

    :before {
      content: "";
      position: absolute;
      left: -30px;
      width: 0px;
      height: 0px;
      border-left: 30px solid transparent;
      border-bottom: 30px solid #6abd45;
      -webkit-transition: all ease-in 0.2s;
      transition: all ease-in 0.2s;
    }

    img {
      height: 8px;
      transform: rotate(90deg);
      transition: all ease-in-out 0.2s;
      margin: 0 10px;
    }
  }

  .avancada {
    flex-direction: column;
    margin-top: 30px;

    .opcoes__group {
      width: 100%;
      max-width: 420px;
      margin: 40px auto 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .opcoes__busca {
      width: 100%;
      max-width: 420px;
      margin: 40px auto 0 auto;

      > span {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`;

export const Categoria = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;

  > span {
    position: absolute;
    top: -20px;

    font-size: 15px;
    font-weight: bold;
    color: #4a4a4a;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 135px;
    height: 40px;
    margin-right: 3px;
    border-bottom: 1px solid #4a4a4a;
    transition: border-bottom ease-in 0.1s;
    cursor: pointer;

    &:last-of-type {
      margin: 0;
    }

    &:hover {
      border-bottom: 2px solid #4a4a4a;
    }

    span {
      font-size: 12px;
      font-weight: normal;
      color: #4a4a4a;
      transition: font-size ease-in 0.1s;
    }
  }

  .active {
    border: none !important;
    background: #6abd45;

    span {
      color: #fff;
    }
  }
`;

export const CategoriaMobile = styled(Categoria)`
  flex-direction: column;
  width: 100%;

  > div {
    width: 100%;
    margin: unset;
    margin-top: 15px;

    &:last-of-type {
      margin-top: 15px;
    }
  }
`;

export const Localizacao = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 270px;
  height: 40px;
  margin-left: 40px;

  > span {
    position: absolute;
    top: -20px;

    font-size: 15px;
    font-weight: bold;
    color: #4a4a4a;
  }
`;

export const LocalizacaoMobile = styled(Localizacao)`
  margin: unset;
  margin-top: 45px;
  width: 100%;

  > span {
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Filtrar = styled.div`
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  background: #fff;

  @media (max-width: 480px) {
    width: 100%;
    position: relative;
    bottom: unset;
    left: unset;
    transform: none;
    background-color: #33499c;
    margin: 10px 0px;
  }

  > div {
    :before {
      display: none;
    }
  }
`;

export const Preco = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 295px;
  height: 40px;

  > span {
    position: absolute;
    top: -20px;

    font-size: 15px;
    font-weight: bold;
    color: #4a4a4a;
  }

  div {
    height: 40px;
    display: flex;
    align-items: center;

    span {
      font-size: 15px;
      font-weight: bold;
      color: #4a4a4a;
      margin: 0 9px;
    }
  }
`;

export const PrecoMobile = styled(Preco)`
  width: 100%;
  max-width: 420px;
  margin: 30px auto 0 auto;

  > span {
    left: 50%;
    transform: translateX(-50%);
  }

  > div {
    display: grid;
    grid-template-columns: 1fr 30px 1fr;

    span {
      align-self: center;
      margin: 0;
    }
  }
`;

export const Opcoes = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  width: ${props => props.width};
  height: 40px;

  > span {
    position: absolute;
    top: -20px;

    font-size: 15px;
    font-weight: bold;
    color: #4a4a4a;
  }

  > input {
    width: ${props => props.width};
    height: 100%;
    font-size: 15px;
    font-weight: normal;
    text-indent: 5px;
    color: #4a4a4a;
    border: none;
    border-bottom: 1px solid #4a4a4a;

    &::placeholder {
      font-size: 13px;
      font-weight: normal;
      color: #b3b3b3;
    }
  }
`;
