import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  color: #fff;
  border: none;
  align-self: center;

  width: ${props => props.width};
  height: 30px;
  background: ${props => props.bgColor};
  display: flex;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
  margin-left: 30px;
  transition: all ease-in 0.2s;
  cursor: pointer;

  img {
    height: 8px;
    padding: unset;
    margin: 0 10px;
  }

  a,
  > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #fff;
  }

  &:before {
    content: '';
    position: absolute;
    left: -30px;
    width: 0px;
    height: 0px;
    border-left: 30px solid transparent;
    border-bottom: 30px solid ${props => props.bgColor};
    transition: all ease-in 0.2s;
  }

  &:hover {
    background: ${props => props.bgColorHover};

    &:before {
      border-bottom-color: ${props => props.bgColorHover};
    }
  }
`;
