import React from 'react';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import store from './store';
import history from './routes/history';
import Routes from './routes';

import GlobalStyle from './styles/global';
import ScrollToTop from './components/ScrollToTop';
import Popup from './components/Popup';

const trackingId = 'UA-59244762-2'; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ScrollToTop>
        <GlobalStyle />
        <ToastContainer
          position="top-right"
          autoClose={15000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover={false}
        />
        <Popup />
        <Routes />
      </ScrollToTop>
    </ConnectedRouter>
  </Provider>
);

export default App;
