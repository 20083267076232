/* eslint-disable import/no-unresolved */
import React, { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import Responsive from "react-responsive";

import $ from "jquery";

// Styles
import { Container, ContainerMobile, Gallery, GalleryMobile } from "./styles";

// Components
import BgButton from "../BgButton";

// Setando jquery como variavel global e importanto fancybox
window.jQuery = $;
require("@fancyapps/fancybox/dist/jquery.fancybox");

function reducer(state, action) {
  switch (action.type) {
    case "load":
      return { imagesLength: state.imagesLength + 6 };

    default:
      throw new Error();
  }
}

export default function Galeria({ images, label, slug }) {
  const [gallery, dispatch] = useReducer(reducer, { imagesLength: 6 });

  const Desktop = props => <Responsive {...props} minWidth={992} />;
  const Mobile = props => <Responsive {...props} maxWidth={991} />;

  // Iniciando fancybox
  useEffect(() => {
    $(`[data-fancybox="${slug}"]`).fancybox({});
  }, [slug]);

  return (
    <>
      <Desktop>
        <Container>
          <h2>{label}</h2>

          <Gallery>
            {images.map((image, index) => {
              if (index < gallery.imagesLength) {
                if (index % 2 !== 0) {
                  return (
                    <a
                      key={image.id}
                      href={image.sizes.large}
                      data-fancybox={slug}
                      // data-caption={imagem.caption}
                    >
                      <img src={image.sizes.large} alt={image.title} />
                    </a>
                  );
                }
                return (
                  <a
                    key={image.id}
                    href={image.sizes.large}
                    data-fancybox={slug}
                    // data-caption={imagem.caption}
                    className="image__small"
                  >
                    <img src={image.sizes.large} alt={image.title} />
                  </a>
                );
              }
              return null;
            })}
          </Gallery>

          {gallery.imagesLength <= images.length && (
            <div className="gallery__button">
              <BgButton
                handleClick={() => dispatch({ type: "load" })}
                width="240px"
                bgColor="#6abd45"
                bgColorHover="#84d660"
              >
                Carregar mais imagens
              </BgButton>
            </div>
          )}
        </Container>
      </Desktop>

      <Mobile>
        <ContainerMobile>
          <h2>{label}</h2>

          <GalleryMobile>
            {images.map((image, index) => {
              if (index < gallery.imagesLength) {
                if (index % 2 !== 0) {
                  return (
                    <a
                      key={image.id}
                      href={image.sizes.large}
                      data-fancybox={slug}
                      // data-caption={imagem.caption}
                    >
                      <img src={image.sizes.large} alt={image.title} />
                    </a>
                  );
                }
                return (
                  <a
                    key={image.id}
                    href={image.sizes.large}
                    data-fancybox={slug}
                    // data-caption={imagem.caption}
                    className="image__small"
                  >
                    <img src={image.sizes.large} alt={image.title} />
                  </a>
                );
              }
              return null;
            })}
          </GalleryMobile>

          {gallery.imagesLength <= images.length && (
            <div className="gallery__button">
              <BgButton
                handleClick={() => dispatch({ type: "load" })}
                width="240px"
                bgColor="#6abd45"
                bgColorHover="#84d660"
              >
                Carregar mais imagens
              </BgButton>
            </div>
          )}
        </ContainerMobile>
      </Mobile>
    </>
  );
}

Galeria.propTypes = {
  label: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape()).isRequired
};
