/* eslint-disable import/no-unresolved */

import styled from "styled-components";

import SearchBanner from "../../assets/images/search-banner.jpg";

export const Container = styled.div`
  width: 80%;
  max-width: 1240px;
  margin: auto;
`;

export const Banner = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
  background: url(${SearchBanner}) no-repeat center;
  background-size: cover;

  h2 {
    width: 640px;
    font-family: "Noirden";
    font-size: 59px;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
    line-height: 61px;
    letter-spacing: 3px;
    color: #fff;
  }
`;

export const BannerTablet = styled(Banner)`
  height: 300px;
`;

export const BannerMobile = styled(Banner)`
  height: 180px;

  h2 {
    width: 80%
    margin: auto;
    font-size: 25px;
    text-align: center;
    line-height: 35px;
  }
`;

export const Destaque = styled.section`
  /* height: 580px; */
  padding: 70px 10%;
  background: #f3f3f3;

  h3 {
    font-family: "Noirden";
    font-size: 45px;
    font-weight: 200;
    text-align: center;
    color: #4a4a4a;

    span {
      font-weight: normal;
      color: #6abd45;
    }
  }
`;

export const EmpDestaque = styled.div`
  position: relative;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1125px;
  height: 350px;
  background: #fff;
  padding-left: 125px;

  > img {
    width: 100%;
    max-width: 200px;
    height: 290px;
    object-fit: cover;
    margin-left: 30px;
  }
`;

export const Square = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -125px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  background: #6abd45;

  > span {
    width: 50%;
    font-family: "Noirden";
    font-size: 35px;
    font-weight: normal;
    word-wrap: break-word;
    color: #fff;
  }
`;

export const EmpInfos = styled.div`
  max-width: 380px;

  h4 {
    font-family: "Noirden";
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 3px;
    color: #4a4a4a;
    margin-bottom: 15px;
  }

  p {
    font-size: 15px;
    font-weight: normal;
    color: #4a4a4a;

    &:first-of-type {
      margin-bottom: 15px;
    }
    &:last-of-type {
      margin-bottom: 20px;
    }
  }

  > span {
    font-size: 15px;
    font-weight: normal;
    color: #4a4a4a;

    b {
      font-size: 30px;

      span {
        font-size: 15px;
      }
    }
  }

  > div {
    margin-top: 40px;
  }
`;

export const EmpImages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 290px;
  margin-right: 30px;

  img {
    width: 100%;
    max-width: 250px;
    height: 49%;
    object-fit: cover;
  }
`;

export const Relevantes = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 135px;
  width: 80%;
  max-width: 1250px;
  margin: auto;
  padding: 85px 0 30px 0;

  &:after {
    content: "";
    position: absolute;
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 80%;
    background: #979797;
  }

  h4 {
    font-family: "Noirden";
    font-size: 35px;
    font-weight: normal;
    color: #33499c;
  }

  .erro {
    margin-top: 60px;
  }
`;

export const RelevantesTablet = styled(Relevantes)`
  grid-template-columns: 1fr;
  grid-gap: 50px;

  &:after {
    display: none;
  }
`;

export const RelevantesMobile = styled(Relevantes)`
  grid-template-columns: 1fr;
  grid-gap: 50px;

  &:after {
    display: none;
  }

  h4 {
    text-align: center;
  }

  .vermais {
    width: 100%;
    height: fit-content;
    font-weight: bold;
    color: #fff;
    background-color: #33499c;
    padding: 10px 0px;
    border: none;
  }
`;

export const Comparador = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  max-width: 1080px;
  height: 350px;
  margin: auto;

  > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 50%;
    font-family: "Noirden";
    text-align: center;
    margin-right: 30px;

    > h3 {
      font-size: 40px;
      font-weight: bold;
      color: #6abd45;
    }

    > p {
      font-size: 22px;
      font-weight: normal;
      color: #6b6b6b;
      margin-top: 10px;
      margin-bottom: 35px;
    }
  }

  > div:last-child {
    display: flex;
    align-items: center;
    height: 70%;
    margin: auto;

    > img {
      width: 45%;
      height: 100%;
      object-fit: cover;
      margin: auto;
    }
  }
`;

export const ComparadorMobile = styled(Comparador)`
  > div:last-child {
    height: unset;
  }

  > div:first-child {
    width: 100%;
    max-width: unset;
    margin: unset;

    h3 {
      font-size: 30px;
    }

    p {
      font-size: 18px;
    }
  }
`;
