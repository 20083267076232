import { call, put } from 'redux-saga/effects';
import api from '../../services/api';

import ComparadorActions from '../ducks/comparador';

export function* getEmpreendimentos() {
  try {
    const response = yield call(api.get, '/empreendimentos');

    yield put(ComparadorActions.getEmpreendimentosSuccess(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* getFiltredUnidades({ empId, pavimento }) {
  // busca?empreendimento=828&pavimento=9

  try {
    const response = yield call(api.get, `/busca?empreendimento=${empId}&pavimento=${pavimento}`);

    yield put(ComparadorActions.filterUnidadesSuccess(response.data));
  } catch (err) {
    yield put(ComparadorActions.filterUnidadesSuccess([]));
  }
}
