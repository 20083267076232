/* eslint-disable no-extra-boolean-cast */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import Responsive from "react-responsive";
import queryString from "query-string";

import createNumberMask from "text-mask-addons/dist/createNumberMask";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SearchActions from "../../store/ducks/search";

// Components
import BgButton from "../BgButton";
import SelectUi from "../SelectUi";
import InputUi from "../InputUi";

import {
  Container,
  Search,
  SearchMobile,
  Categoria,
  CategoriaMobile,
  Localizacao,
  LocalizacaoMobile,
  Filtrar,
  Preco,
  PrecoMobile,
  Opcoes
} from "./styles";

// Assets
import ArrowIcon from "../../assets/images/arrow-icon.svg";

function Filter({ getSearchRequest, avancada, params }) {
  const [buscaAvancada, setBuscaAvancada] = useState(avancada || false);
  const [categoria, setCategoria] = useState("lancamento");
  const [bairro, setBairro] = useState({ value: "todos", error: "" });
  const [precoInicial, setPrecoInicial] = useState({ value: "", error: "" });
  const [precoFinal, setPrecoFinal] = useState({ value: "", error: "" });
  const [quartos, setQuartos] = useState("");
  const [suites, setSuites] = useState("");
  const [garagens, setGaragens] = useState("");
  const [area, setArea] = useState();

  const Desktop = props => <Responsive {...props} minWidth={992} />;
  const Mobile = props => <Responsive {...props} maxWidth={991} />;

  const numberOptions = [
    {
      value: null,
      label: ""
    },
    {
      value: 1,
      label: "1"
    },
    {
      value: 2,
      label: "2"
    },
    {
      value: 3,
      label: "3"
    },
    {
      value: 4,
      label: "4"
    }
  ];

  const numberMask = createNumberMask({
    prefix: "R$: ",
    suffix: ",00",
    thousandsSeparatorSymbol: "."
  });

  useEffect(() => {
    if (!!params) {
      const query = queryString.parse(params);

      if (!!query.tipo) {
        setCategoria(query.tipo);
      }

      if (!!query.bairro) {
        console.log(query.bairro);
        setBairro({ error: "", value: query.bairro });
      }

      if (!!query.preco_de && !!query.preco_ate) {
        setPrecoInicial({ error: "", value: query.preco_de });
        setPrecoFinal({ error: "", value: query.preco_ate });
      }

      if (!!query.quartos) {
        setQuartos(query.quartos);
      }

      if (!!query.suites) {
        setSuites(query.suites);
      }

      if (!!query.garagens) {
        setGaragens(query.garagens);
      }

      if (!!query.m2) {
        setArea(query.m2);
      }
    }
  }, [params]);

  function removeNumberMask(value) {
    const number = value
      .split(".")
      .join("")
      .replace(",00", "")
      .replace("R$: ", "");
    return number;
  }

  function handleFilter() {
    // Validations
    let validationError = false;

    // Validação Bairro <- Não enviar por enquanto
    if (!bairro.value) {
      setBairro({ ...bairro, error: "Selecione um bairro" });
      validationError = true;
    }

    // Validação Faixa de Preço
    if (!!precoInicial.value || !!precoFinal.value) {
      if (!precoInicial.value) {
        setPrecoInicial({ ...precoInicial, error: "Informe valor inicial" });
        validationError = true;
      }
      if (!precoFinal.value) {
        setPrecoFinal({ ...precoFinal, error: "Informe valor final" });
        validationError = true;
      }

      if (
        parseInt(removeNumberMask(precoInicial.value), 10) >
        parseInt(removeNumberMask(precoFinal.value), 10)
      ) {
        setPrecoInicial({
          ...precoInicial,
          error: "Valor inicial nao pode ser maior que final"
        });
        validationError = true;
      }
    }

    // eslint-disable-next-line no-useless-return
    if (validationError) return;

    const params = {
      tipo: categoria,
      bairro: bairro.value === "todos" ? null : bairro.value,
      preco_de: !!precoInicial.value
        ? removeNumberMask(precoInicial.value)
        : null,
      preco_ate: !!precoFinal.value ? removeNumberMask(precoFinal.value) : null,
      quartos,
      suites,
      garagens,
      m2: area
    };

    const stringfied = queryString.stringify(params);

    getSearchRequest(stringfied);
  }

  return (
    <Container>
      <Desktop>
        <Search
          style={{ gridTemplateRows: buscaAvancada ? "90px 90px" : "90px" }}
        >
          <div>
            <Categoria>
              <span>O que você procura?</span>
              <div
                role="button"
                tabIndex={0}
                className={categoria === "lancamento" ? "active" : null}
                onClick={() => setCategoria("lancamento")}
              >
                <span>Lançamento</span>
              </div>
              <div
                role="button"
                tabIndex={0}
                className={categoria === "na_planta" ? "active" : null}
                onClick={() => setCategoria("na_planta")}
              >
                <span>Na planta</span>
              </div>
              <div
                role="button"
                tabIndex={0}
                className={categoria === "pronto_morar" ? "active" : null}
                onClick={() => setCategoria("pronto_morar")}
              >
                <span>Pronto para morar</span>
              </div>
            </Categoria>

            <Localizacao>
              <span>Qual bairro?</span>
              <SelectUi
                name="bairro"
                width="100%"
                value={bairro.value}
                error={bairro.error}
                handleChange={e =>
                  setBairro({ value: e.target.value, error: "" })
                }
                options={[
                  { value: "todos", label: "Todos" },
                  { value: "orfas", label: "Orfãs" },
                  { value: "centro", label: "Centro" }
                ]}
                placeholder="Ex: Jardim Carvalho"
              />
            </Localizacao>

            {/* eslint-disable-next-line react/button-has-type */}
            <button onClick={() => setBuscaAvancada(!buscaAvancada)}>
              Busca avançada{" "}
              <img
                src={ArrowIcon}
                alt="Seta"
                style={{
                  transform: buscaAvancada ? "rotate(-90deg)" : "rotate(90deg)"
                }}
              />
            </button>
          </div>

          <div style={{ display: buscaAvancada ? "flex" : "none" }}>
            <Preco>
              <span>Faixa de preço</span>
              <div>
                <InputUi
                  value={precoInicial.value}
                  handleChange={e => {
                    setPrecoInicial({ value: e.target.value, error: "" });
                    setPrecoFinal({ ...precoFinal, error: "" });
                  }}
                  withMask
                  mask={numberMask}
                  error={precoInicial.error || precoFinal.error}
                  errorMessage={precoInicial.error}
                  width="125"
                />
                <span>Até</span>
                <InputUi
                  value={precoFinal.value}
                  handleChange={e => {
                    setPrecoFinal({ value: e.target.value, error: "" });
                    setPrecoInicial({ ...precoInicial, error: "" });
                  }}
                  withMask
                  mask={numberMask}
                  error={precoInicial.error || precoFinal.error}
                  errorMessage={precoFinal.error}
                  width="125"
                />
              </div>
            </Preco>

            <Opcoes width="70px">
              <span>Quartos</span>
              <SelectUi
                name="quartos"
                width="100%"
                value={quartos}
                handleChange={e => setQuartos(e.target.value)}
                options={numberOptions}
                placeholder="Ex: 1"
              />
            </Opcoes>

            <Opcoes width="70px">
              <span>Suítes</span>
              <SelectUi
                name="suites"
                width="100%"
                value={suites}
                handleChange={e => setSuites(e.target.value)}
                options={numberOptions}
                placeholder="Ex: 1"
              />
            </Opcoes>

            <Opcoes width="70px">
              <span>Garagem</span>
              <SelectUi
                name="garagem"
                width="100%"
                value={garagens}
                handleChange={e => setGaragens(e.target.value)}
                options={numberOptions}
                placeholder="Ex: 1"
              />
            </Opcoes>

            <Opcoes width="70px">
              <span>Área m²</span>
              <InputUi
                value={area}
                handleChange={e => setArea(e.target.value)}
              />
            </Opcoes>

            {/* <Opcoes width="210px">
              <span>O que você busca?</span>
              <InputUi placeholder="Ex: piscina, churrasqueira..." />
            </Opcoes> */}
          </div>

          <Filtrar>
            <BgButton
              handleClick={handleFilter}
              width="140px"
              bgColor="#6abd45"
              bgColorHover="#84d660"
            >
              Filtrar
            </BgButton>
          </Filtrar>
        </Search>
      </Desktop>
      <Mobile>
        <SearchMobile>
          <CategoriaMobile>
            <span>O que você procura?</span>
            <div
              role="button"
              tabIndex={0}
              className={categoria === "lancamento" ? "active" : null}
              onClick={() => setCategoria("lancamento")}
            >
              <span>Lançamento</span>
            </div>
            <div
              role="button"
              tabIndex={0}
              className={categoria === "na_planta" ? "active" : null}
              onClick={() => setCategoria("na_planta")}
            >
              <span>Na planta</span>
            </div>
            <div
              role="button"
              tabIndex={0}
              className={categoria === "pronto_morar" ? "active" : null}
              onClick={() => setCategoria("pronto_morar")}
            >
              <span>Pronto para morar</span>
            </div>
          </CategoriaMobile>

          <LocalizacaoMobile>
            <span>Qual bairro?</span>
            <SelectUi
              name="bairro"
              width="100%"
              value={bairro.value}
              error={bairro.error}
              handleChange={e =>
                setBairro({ value: e.target.value, error: "" })
              }
              options={[
                { value: "todos", label: "Todos" },
                { value: "orfas", label: "Orfãs" },
                { value: "centro", label: "Centro" }
              ]}
              placeholder="Ex: Jardim Carvalho"
            />
          </LocalizacaoMobile>

          {/* eslint-disable-next-line react/button-has-type */}
          <button onClick={() => setBuscaAvancada(!buscaAvancada)}>
            Busca avançada{" "}
            <img
              src={ArrowIcon}
              alt="Seta"
              style={{
                transform: buscaAvancada ? "rotate(-90deg)" : "rotate(90deg)"
              }}
            />
          </button>

          <div
            className="avancada"
            style={{ display: buscaAvancada ? "flex" : "none" }}
          >
            <PrecoMobile>
              <span>Faixa de preço</span>
              <div>
                <InputUi
                  value={precoInicial.value}
                  handleChange={e => {
                    setPrecoInicial({ value: e.target.value, error: "" });
                    setPrecoFinal({ ...precoFinal, error: "" });
                  }}
                  withMask
                  mask={numberMask}
                  error={precoInicial.error || precoFinal.error}
                  errorMessage={precoInicial.error}
                />
                <span>Até</span>
                <InputUi
                  value={precoFinal.value}
                  handleChange={e => {
                    setPrecoFinal({ value: e.target.value, error: "" });
                    setPrecoInicial({ ...precoInicial, error: "" });
                  }}
                  withMask
                  mask={numberMask}
                  error={precoInicial.error || precoFinal.error}
                  errorMessage={precoFinal.error}
                />
              </div>
            </PrecoMobile>

            <div className="opcoes__group">
              <Opcoes width="60px">
                <span>Quartos</span>
                <SelectUi
                  name="quartos"
                  width="100%"
                  value={quartos}
                  handleChange={e => setQuartos(e.target.value)}
                  options={numberOptions}
                  placeholder="Ex: 1"
                />
              </Opcoes>

              <Opcoes width="60px">
                <span>Suítes</span>
                <SelectUi
                  name="suites"
                  width="100%"
                  value={suites}
                  handleChange={e => setSuites(e.target.value)}
                  options={numberOptions}
                  placeholder="Ex: 1"
                />
              </Opcoes>

              <Opcoes width="70px">
                <span>Garagem</span>
                <SelectUi
                  name="garagem"
                  width="100%"
                  value={garagens}
                  handleChange={e => setGaragens(e.target.value)}
                  options={numberOptions}
                  placeholder="Ex: 1"
                />
              </Opcoes>

              <Opcoes width="70px">
                <span>Área m²</span>
                <InputUi
                  value={area}
                  handleChange={e => setArea(e.target.value)}
                />
              </Opcoes>
            </div>

            <Opcoes className="opcoes__busca">
              {/* <span>O que você busca?</span>
              <InputUi placeholder="Ex: piscina, churrasqueira..." /> */}
            </Opcoes>
          </div>

          {/* <Filtrar>
            <button handleClick={handleFilter} className="filtrarmobile">
              Filtrar
              <img
                src={ArrowIcon}
                alt="Seta"
                style={{
                  transform: "rotate(0deg)"
                }}
              />
            </button>
          </Filtrar> */}
          <Filtrar>
            <BgButton handleClick={handleFilter}>Filtrar</BgButton>
          </Filtrar>
        </SearchMobile>
      </Mobile>
    </Container>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(SearchActions, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(Filter);
