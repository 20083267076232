/* eslint-disable import/no-unresolved */
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Main from "../pages/Main";
import Search from "../pages/Search";
import Unidade from "../pages/Unidade";
import Comparar from "../pages/Comparar";

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Main} />
    <Route path="/busca" component={Search} />
    <Route path="/unidade/:slug" component={Unidade} />
    <Route path="/comparar" component={Comparar} />
    <Redirect to="/" />
  </Switch>
);

export default Routes;
