import { all, takeLatest } from 'redux-saga/effects';

import { getUnidades } from './unidades';
import { UnidadesTypes } from '../ducks/unidades';

import { getUnidadePage } from './pages';
import { PagesTypes } from '../ducks/pages';

import { getSearchResults } from './search';
import { SearchTypes } from '../ducks/search';

import { getEmpreendimentos, getFiltredUnidades } from './comparador';
import { ComparadorTypes } from '../ducks/comparador';

export default function* rootSaga() {
  return yield all([
    takeLatest(UnidadesTypes.GET_UNIDADES_REQUEST, getUnidades),
    takeLatest(PagesTypes.GET_UNIDADE_REQUEST, getUnidadePage),
    takeLatest(SearchTypes.GET_SEARCH_REQUEST, getSearchResults),
    takeLatest(ComparadorTypes.GET_EMPREENDIMENTOS_REQUEST, getEmpreendimentos),
    takeLatest(ComparadorTypes.FILTER_UNIDADES_REQUEST, getFiltredUnidades),
  ]);
}
