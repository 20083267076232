/* eslint-disable no-extra-boolean-cast */
/* eslint-disable import/no-unresolved */
import React from "react";
import { Link } from "react-router-dom";

import { Container } from "./styles";

// Assets
import ArrowIcon from "../../assets/images/arrow-icon.svg";

export default function BgButton({
  // eslint-disable-next-line react/prop-types
  handleClick,
  width,
  bgColor,
  bgColorHover,
  href,
  to,
  children,
  ...props
}) {
  if (!!href) {
    return (
      <Container width={width} bgColor={bgColor} bgColorHover={bgColorHover}>
        <a href={href} {...props}>
          {children} <img src={ArrowIcon} alt="Seta" />
        </a>
      </Container>
    );
  }
  if (!!to) {
    return (
      <Container width={width} bgColor={bgColor} bgColorHover={bgColorHover}>
        <Link to={to}>
          {children} <img src={ArrowIcon} alt="Seta" />
        </Link>
      </Container>
    );
  }
  return (
    <Container
      onClick={handleClick}
      width={width}
      bgColor={bgColor}
      bgColorHover={bgColorHover}
    >
      <div>
        {children} <img src={ArrowIcon} alt="Seta" />
      </div>
    </Container>
  );
}
