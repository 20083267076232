/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import Responsive from "react-responsive";

import { Container, ContainerTablet, ContainerMobile } from "./styles";

// Components
import BgButton from "../BgButton";

export default function ApartamentoSingle({ unidade }) {
  const Desktop = props => <Responsive {...props} minWidth={992} />;
  const Tablet = props => (
    <Responsive {...props} minWidth={768} maxWidth={991} />
  );
  const Mobile = props => <Responsive {...props} maxWidth={767} />;

  const [lazer, setLazer] = useState("");

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    if (unidade) {
      let arr = Object.keys(unidade.diferenciais).filter(
        key => unidade.diferenciais[key] === true
      );
      arr = arr.map(item =>
        item
          .replace("salao_jogos", "salão de jogos")
          .replace("salao_festas", "salão de festas")
          .replace("co", "ço")
          .replace("_", " ")
      );
      setLazer(capitalizeFirstLetter(arr.join(", ")));
    }
  }, [unidade]);

  return (
    <>
      <Desktop>
        <Container to={`/unidade/${unidade.slug}`}>
          <div>
            <img
              src={unidade.fachada.sizes.large}
              alt={unidade.fachada.title}
            />
          </div>
          <div>
            <h5>{unidade.title}</h5>
            <p>{`${lazer.substring(0, 70)}...`}</p>
            <p>Apartamentos a partir de:</p>
            <span>
              R${" "}
              <b>
                {parseInt(unidade.valor, 10).toLocaleString("pt-BR")}
                <span>,00</span>
              </b>
            </span>
            <BgButton width="190px" bgColor="#6abd45" bgColorHover="#84d660">
              Saiba Mais
            </BgButton>
          </div>
        </Container>
      </Desktop>

      <Tablet>
        <ContainerTablet to={`/unidade/${unidade.slug}`}>
          <div>
            <img
              src={unidade.fachada.sizes.large}
              alt={unidade.fachada.title}
            />
          </div>
          <div>
            <h5>{unidade.title}</h5>
            <p>{`${lazer.substring(0, 70)}...`}</p>
            <p>Apartamentos a partir de:</p>
            <span>
              R${" "}
              <b>
                {unidade.valor}
                <span>,00</span>
              </b>
            </span>
            <BgButton width="190px" bgColor="#6abd45" bgColorHover="#84d660">
              Saiba Mais
            </BgButton>
          </div>
        </ContainerTablet>
      </Tablet>

      <Mobile>
        <ContainerMobile to={`/unidade/${unidade.slug}`}>
          <div>
            <img
              src={unidade.fachada.sizes.large}
              alt={unidade.fachada.title}
            />
          </div>
          <div>
            <h5>{unidade.title}</h5>
            <p>{`${lazer.substring(0, 70)}...`}</p>
            <p>Apartamentos a partir de:</p>
            <span>
              R${" "}
              <b>
                {parseInt(unidade.valor, 10).toLocaleString("pt-BR")}
                <span>,00</span>
              </b>
            </span>
            <BgButton width="190px" bgColor="#6abd45" bgColorHover="#84d660">
              Saiba Mais
            </BgButton>
          </div>
        </ContainerMobile>
      </Mobile>
    </>
  );
}
