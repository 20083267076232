import { call, put } from 'redux-saga/effects';
import api from '../../services/api';

import PagesActions from '../ducks/pages';

export function* getUnidadePage({ slug }) {
  try {
    const unidadeResponse = yield call(api.get, `/unidades?slug=${slug}`);

    yield put(PagesActions.getUnidadeSuccess(unidadeResponse.data));
  } catch (err) {
    console.error(err);
  }
}
