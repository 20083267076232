import { createGlobalStyle } from 'styled-components';

import 'normalize.css';
import './fonts.css';
import '@fancyapps/fancybox/src/css/core.css';
import 'react-toastify/dist/ReactToastify.min.css';

const GlobalStyle = createGlobalStyle`
  * {
      margin: 0;
      padding: 0;
      outline: 0;
      box-sizing: border-box !important;
    }

    body {
      font-family: 'Libre Franklin', sans-serif;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased !important;
    }

    a {
      text-decoration: none;
    }
`;

export default GlobalStyle;
