/* eslint-disable react/no-danger */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import Responsive from "react-responsive";

import $ from "jquery";
import GoogleMapReact from "google-map-react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import PagesActions from "../../store/ducks/pages";
import ComparadorActions from "../../store/ducks/comparador";

import {
  Container,
  Empreendimento,
  EmpreendimentoMobile,
  Comparador,
  ComparadorMobile,
  Informacoes,
  InformacoesMobile,
  Mapa
} from "./styles";

// Components
import Header from "../../components/Header";
import Simulador from "../../components/Simulador";
import Footer from "../../components/Footer";
import BgButton from "../../components/BgButton";
import Galeria from "../../components/Galeria";

// Assets
import PinIcon from "../../assets/images/pin.svg";

// Setando jquery como variavel global e importanto fancybox
window.jQuery = $;
require("@fancyapps/fancybox/dist/jquery.fancybox");

function Unidade({ match, getUnidadeRequest, unidade, setCompare, push }) {
  const Desktop = props => <Responsive {...props} minWidth={992} />;
  const Mobile = props => <Responsive {...props} maxWidth={991} />;

  const [contatoShown, setContatoShown] = useState(false);
  const [lazer, setLazer] = useState("");

  // Get Page Infos
  useEffect(() => {
    getUnidadeRequest(match.params.slug);
  }, [getUnidadeRequest, match.params.slug]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    if (unidade) {
      document.title = `${
        unidade[0].title
      } | Vendas de apartamentos - Construtora Miquelão`;
      let arr = Object.keys(unidade[0].diferenciais).filter(
        key => unidade[0].diferenciais[key] === true
      );
      arr = arr.map(item =>
        item
          .replace("salao_jogos", "salão de jogos")
          .replace("salao_festas", "salão de festas")
          .replace("co", "ço")
          .replace("_", " ")
      );
      setLazer(capitalizeFirstLetter(arr.join(", ")));
    }
  }, [unidade]);

  useEffect(() => {
    $('[data-fancybox="planta"]').fancybox({});
  }, []);

  function toContato() {
    setContatoShown(true);
  }

  return (
    <>
      <Header />
      <Container>
        {/* Empreendimento Inicio */}
        <Desktop>
          {unidade ? (
            <Empreendimento>
              <div>
                <img
                  src={unidade[0].logo.sizes.large}
                  alt={unidade[0].logo.title}
                />
                <h2>Sobre o empreendimento</h2>
                <p
                  className="empreedimento__descricao"
                  dangerouslySetInnerHTML={{
                    __html: unidade[0].caracteristicas.texto
                  }}
                />
                <p className="empreedimento__pre">Valor:</p>
                <p className="empreedimento__valor">
                  R${" "}
                  <span>
                    {parseInt(unidade[0].valor, 10).toLocaleString("pt-BR")}
                  </span>
                  ,00 *
                </p>
                <p className="empreendimento__obs">{`* Nesse valor estão inclusas ${
                  unidade[0].nr_garagem
                } vagas de garagem, vagas extras negociadas a parte.`}</p>
                <BgButton
                  handleClick={() => toContato()}
                  width="230px"
                  bgColor="#6abd45"
                  bgColorHover="#84d660"
                >
                  Entrar em contato
                </BgButton>
              </div>

              <div>
                <div className="square">
                  <span>{unidade[0].tipo.label}</span>
                </div>
                <img
                  src={unidade[0].fachada.sizes.large}
                  alt={unidade[0].fachada.title}
                />
              </div>
            </Empreendimento>
          ) : (
            <div style={{ height: "740px" }} />
          )}
        </Desktop>

        <Mobile>
          {!!unidade && (
            <EmpreendimentoMobile>
              <div>
                <img
                  src={unidade[0].logo.sizes.large}
                  alt={unidade[0].logo.title}
                />
                <h2>Sobre o empreendimento</h2>
                <p
                  className="empreedimento__descricao"
                  dangerouslySetInnerHTML={{
                    __html: unidade[0].caracteristicas.texto
                  }}
                />
                <p className="empreedimento__pre">Valor:</p>
                <p className="empreedimento__valor">
                  R${" "}
                  <span>
                    {parseInt(unidade[0].valor, 10).toLocaleString("pt-BR")}
                  </span>
                  ,00
                </p>
                <BgButton
                  handleClick={() => toContato()}
                  width="230px"
                  bgColor="#6abd45"
                  bgColorHover="#84d660"
                >
                  Entrar em contato
                </BgButton>
              </div>

              <div>
                <div className="square">
                  <span>
                    Na
                    <br />
                    Planta
                  </span>
                </div>
                <img
                  src={unidade[0].fachada.sizes.large}
                  alt={unidade[0].fachada.title}
                />
              </div>
            </EmpreendimentoMobile>
          )}
        </Mobile>
        {/* Empreendimento Fim */}

        {/* Comparador Inicio */}
        <Desktop>
          <Comparador>
            <div>
              <h3>Está com dúvidas sobre qual unidade comprar?</h3>
              <p>Compare-as e veja qual mais lhe agrada.</p>
              <BgButton
                handleClick={() => {
                  setCompare(unidade[0]);
                  push("/comparar");
                }}
                width="240px"
                bgColor="#33499c"
                bgColorHover="#3b55b6"
              >
                Comparar Unidade
              </BgButton>
            </div>
          </Comparador>
        </Desktop>
        <Mobile>
          <ComparadorMobile>
            <div>
              <h3>Está com dúvidas sobre qual unidade comprar?</h3>
              <p>Compare-as e veja qual mais lhe agrada.</p>
              <BgButton width="240px" bgColor="#33499c" bgColorHover="#3b55b6">
                Comparar Unidade
              </BgButton>
            </div>
          </ComparadorMobile>
        </Mobile>
        {/* Comparador Fim */}

        {/* Informacoes Inicio */}
        <Desktop>
          {!!unidade && !!unidade[0].planta ? (
            <Informacoes>
              <div>
                <div className="info">
                  <h3>Lazer</h3>
                  <p>{lazer}</p>
                </div>

                <div className="info">
                  <h3>Localização</h3>
                  <p>{`${unidade[0].pavimento}° pavimento`}</p>
                  <p>{unidade[0].localizacao.address}</p>
                </div>

                <div className="caracteristicas">
                  {unidade[0].planta.caracteristicas.map(caracteristica => (
                    <div key={Math.random()}>
                      {caracteristica.caracteristica}
                    </div>
                  ))}
                </div>

                <div className="valor">
                  <span>{`${unidade[0].nr_m2_total} m²`}</span>
                  <div>
                    <p>Área total</p>
                    <p style={{ fontSize: "12px", marginTop: "5px" }}>{`${
                      unidade[0].nr_m2
                    } m² - Área privativa`}</p>
                  </div>
                </div>
              </div>
              <a
                href={unidade[0].planta.imagem.sizes.large}
                data-fancybox="planta"
              >
                <img
                  src={unidade[0].planta.imagem.sizes.large}
                  alt={unidade[0].planta.imagem.title}
                />
              </a>
            </Informacoes>
          ) : (
            <div style={{ height: "980px" }} />
          )}
        </Desktop>
        <Mobile>
          {!!unidade && !!unidade[0].planta && (
            <InformacoesMobile>
              <div>
                <div className="info">
                  <h3>Lazer</h3>
                  <p>{lazer}</p>
                </div>

                <div className="info">
                  <h3>Localização</h3>
                  <p>{`${unidade[0].pavimento}° pavimento`}</p>
                  <p>{unidade[0].localizacao.address}</p>
                </div>

                <div className="caracteristicas">
                  {unidade[0].planta.caracteristicas.map(caracteristica => (
                    <div key={Math.random()}>
                      {caracteristica.caracteristica}
                    </div>
                  ))}
                </div>

                <div className="valor">
                  <span>{`${unidade[0].nr_m2_total} m²`}</span>
                  <div>
                    <p>Área total</p>
                    <p style={{ fontSize: "12px", marginTop: "5px" }}>{`${
                      unidade[0].nr_m2
                    } m² - Área privativa`}</p>
                  </div>
                </div>
              </div>
              <a
                href={unidade[0].planta.imagem.sizes.large}
                data-fancybox="planta"
              >
                <img
                  src={unidade[0].planta.imagem.sizes.large}
                  alt={unidade[0].planta.imagem.title}
                />
              </a>
            </InformacoesMobile>
          )}
        </Mobile>
        {/* Informacoes Fim */}

        {!!unidade && !!unidade[0].galeria_empreendimento && (
          <Galeria
            label="Área comum"
            slug="comum"
            images={unidade[0].galeria_empreendimento}
          />
        )}
        {!!unidade && !!unidade[0].galeria_unidade && (
          <Galeria
            label="Área privativa"
            slug="privativa"
            images={unidade[0].galeria_unidade}
          />
        )}

        {!!unidade && (
          <Mapa>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyCI_v75h1fLfSS3bBWxEHmiIUY8xFPfzQY"
              }}
              defaultCenter={{ lat: -25.0915892, lng: -50.1652471 }}
              defaultZoom={15}
              center={{
                lat: parseFloat(unidade[0].localizacao.lat),
                lng: parseFloat(unidade[0].localizacao.lng)
              }}
            >
              <img
                className="pin__icon"
                lat={unidade[0].localizacao.lat}
                lng={unidade[0].localizacao.lng}
                title="Empreendimento"
                src={PinIcon}
                alt="Pin"
              />
            </GoogleMapReact>
          </Mapa>
        )}
      </Container>
      <Simulador unidadeShownContato={contatoShown} />
      <Footer />
    </>
  );
}

const mapStateToProps = state => ({
  unidade: state.pages.unidadePage.unidade
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...PagesActions, ...ComparadorActions, push }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Unidade);
