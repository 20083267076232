/* eslint-disable import/no-unresolved */
import React, { Fragment } from 'react';

import { Container, Content, Ade } from './styles';

// Components
import BgButton from '../BgButton';

// Assets
import Logo from '../../assets/images/New_logo_negativa.png';
import Coracao from '../../assets/images/coracao.svg';

const Footer = () => (
  <Fragment>
    <Container>
      <Content>
        <div className="infos">
          <div className="institucional">
            <h3>Institucional</h3>
            <a href="https://miquelao.com.br/sobre">Sobre</a>
            <a href="https://miquelao.com.br/#empreendimentos">
              Empreendimentos
            </a>
            <a href="https://miquelao.com.br/blog">Blog</a>
            <a href="https://miquelao.com.br/trabalhe-conosco">
              Trabalhe Conosco
            </a>
            <a href="https://miquelao.com.br/#contato">Contato</a>
          </div>

          <div className="contato">
            <h3>Contato</h3>
            <div className="endereco">
              <span>
                Rua
                <span> Sete de Setembro </span>
                , 800
              </span>
              <span>
                Centro |
                {' '}
                <span>Ponta Grossa</span>
                {' '}
                | PR
              </span>
              <span>CEP: 84010-350</span>
            </div>
            <span>
              42
              {' '}
              <span>3224-5121</span>
            </span>
            <span>
              contato@
              <span>miquelao</span>
              .com.br
            </span>
          </div>
        </div>

        <div className="logo">
          <img src={Logo} alt="Miquelão" />
        </div>
        <div className="calltoaction">
          <div>
            <h3>
              Se interessou por algum de
              {' '}
              <br />
              nossos empreendimentos?
            </h3>
            <span>Nós podemos ter a solução perfeita para você!</span>

            <BgButton
              to="/"
              width="220px"
              bgColor="#33499c"
              bgColorHover="#3b55b6"
            >
              Compre um apê miquelão
            </BgButton>
          </div>
        </div>
      </Content>
    </Container>
    <Ade>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <span
        role="button"
        tabIndex="0"
        onClick={() => {
          window.open('https://agenciaade.com.br/', '_blank');
        }}
      >
        Desenvolvido com
        {' '}
        <img src={Coracao} alt="coração" />
        {' '}
        por
        agenciaade.com.br
      </span>
    </Ade>
  </Fragment>
);

export default Footer;
