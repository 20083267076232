/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Responsive from 'react-responsive';

import {
  Container,
  MobileContainer,
  Contato,
  Search,
  MobileSearch,
  MobileMenu,
} from './styles';

// Components
import BgButton from '../BgButton';

// Assets
import LogoMiquelao from '../../assets/images/New_logo.png';
import WhatsappIcon from '../../assets/images/whatsapp-icon.svg';
import PhoneIcon from '../../assets/images/phone-icon.svg';
import ArrowIcon from '../../assets/images/arrow-icon-dark.svg';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const Desktop = props => <Responsive {...props} minWidth={1240} />;
  const Mobile = props => <Responsive {...props} maxWidth={1239} />;

  function handleMenu() {
    setIsMenuOpen(!isMenuOpen);

    if (!isMenuOpen) {
      document
        .getElementsByTagName('body')[0]
        .setAttribute('style', 'overflow-y: hidden !important');
    } else {
      document
        .getElementsByTagName('body')[0]
        .setAttribute('style', 'overflow-y: unset');
    }
  }

  return (
    <>
      <Desktop>
        <Container>
          <Link to="/">
            <img src={LogoMiquelao} className="logooo" alt="Miquelão" />
          </Link>

          <Contato>
            <div>
              <img src={WhatsappIcon} alt="WhatsApp" />
              <span>WhatsApp</span>
              <a
                href="https://api.whatsapp.com/send?phone=5542991073001"
                target="_blank"
                rel="noopener noreferrer"
              >
                42
                {' '}
                <b>99107-3001</b>
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=5542991223001"
                target="_blank"
                rel="noopener noreferrer"
              >
                42
                {' '}
                <b>99122-3001</b>
              </a>
            </div>
            <a href="tel:4232245121">
              <img src={PhoneIcon} alt="Central de Vendas" />
              <span>Central de Vendas</span>
              <span>
                42
                {' '}
                <b>3224-5121</b>
              </span>
            </a>
          </Contato>

          <Search>
            {/* <input type="text" placeholder="Procure pelo empreendimento..." /> */}
          </Search>
          <BgButton
            href="https://miquelao.com.br"
            target="_blank"
            rel="noopener noreferrer"
            width="230px"
            bgColor="#6abd45"
            bgColorHover="#84d660"
          >
            Site Institucional
          </BgButton>
        </Container>
      </Desktop>

      <Mobile>
        <MobileContainer>
          <div role="button" tabIndex={0}>
            <a href="/">
              <img src={LogoMiquelao} alt="Miquelão" />
            </a>
            <div onClick={() => handleMenu()}>
              <img
                className={`open ${isMenuOpen && 'active'}`}
                src={require('../../assets/images/hamburger.png')}
                alt="Seta"
              />
              <img
                className={`close ${isMenuOpen && 'active'}`}
                src={require('../../assets/images/close-hamburger.png')}
                alt="Seta"
              />
            </div>
          </div>
          <MobileSearch>
            {/* <input type="text" placeholder="Procure pelo empreendimento..." /> */}
          </MobileSearch>
        </MobileContainer>
        <MobileMenu style={{ top: isMenuOpen ? '80px' : null }}>
          <div>
            <Link to="/">Início</Link>
          </div>
          <div>
            <a href="https://miquelao.com.br">Ir para site institucional</a>
          </div>
          <div className="divider" />
          <div className="contato">
            <div>
              <img src={WhatsappIcon} alt="WhatsApp" />
              <span>WhatsApp</span>
              <div>
                <a
                  href="https://api.whatsapp.com/send?phone=5542991073001"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  42
                  {' '}
                  <b>99107-3001</b>
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=5542991223001"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  42
                  {' '}
                  <b>99122-3001</b>
                </a>
              </div>
            </div>
          </div>
          <div className="contato">
            <a href="tel:4232245121">
              <img src={PhoneIcon} alt="Central de Vendas" />
              <span>Central de Vendas</span>
              <span>
                42
                {' '}
                <b>3224-5121</b>
              </span>
            </a>
          </div>
        </MobileMenu>
      </Mobile>
    </>
  );
}
