import { call, put } from 'redux-saga/effects';
import _ from 'lodash';

import api from '../../services/api';

import UnidadesActions from '../ducks/unidades';

function category(unidades) {
  let lancamentos = [];
  let prontos = [];
  let planta = [];

  unidades.forEach((unidade) => {
    switch (unidade.tipo.value) {
      case 'lancamento':
        lancamentos.push(unidade);
        break;
      case 'pronto_morar':
        prontos.push(unidade);
        break;
      case 'na_planta':
        planta.push(unidade);
        break;
      default:
        break;
    }
  });

  lancamentos = _.shuffle(lancamentos);
  prontos = _.shuffle(prontos);
  planta = _.shuffle(planta);

  return { lancamentos, prontos, planta };
}

export function* getUnidades() {
  try {
    const response = yield call(api.get, '/unidades');
    const categorias = yield call(category, response.data);

    yield put(UnidadesActions.getUnidadesSuccess(response.data, categorias));
  } catch (err) {
    console.warn(err);
  }
}
