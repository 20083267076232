/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Responsive from 'react-responsive';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ComparadorActions from '../../store/ducks/comparador';

import {
  Container,
  Empreendimentos,
  EmpreendimentosMobile,
  Empreendimento,
  SelectEmp,
  Comparativo,
  Infos,
  InfosMobile,
} from './styles';

// Components
import Header from '../../components/Header';
import Simulador from '../../components/Simulador';
import Footer from '../../components/Footer';
import SelectUi from '../../components/SelectUi';
import BgButton from '../../components/BgButton';

// Assets
import CloseIcon from '../../assets/images/close-icon.svg';

function Comparar({
  unidades,
  empreendimentos,
  filtredUnidades,
  removeCompare,
  getEmpreendimentosRequest,
  filterUnidadesRequest,
  setCompare,
}) {
  const Desktop = props => <Responsive {...props} minWidth={992} />;
  const Mobile = props => <Responsive {...props} maxWidth={991} />;

  const [emp, setEmp] = useState(['', '', '']);
  const [pav, setPav] = useState([
    { total: [], value: '' },
    { total: [], value: '' },
    { total: [], value: '' },
  ]);
  const [uni, setUni] = useState(['', '', '']);

  useEffect(() => {
    document.title = 'Comparador | Vendas de apartamentos - Construtora Miquelão';
    getEmpreendimentosRequest();
    console.log(unidades);
  }, [getEmpreendimentosRequest, unidades]);

  function loadPav(empId, idx) {
    const selectedEmp = empreendimentos.filter(e => e.id === empId);
    const nrPavimentos = selectedEmp[0].acf.total_pavimentos;
    const pavArray = [];
    for (let index = 0; index < nrPavimentos; index++) {
      pavArray.push({ value: index + 1, label: index + 1 });
    }
    if (idx === 0) setPav([{ ...pav[0], total: pavArray }, { ...pav[1] }, { ...pav[2] }]);
    if (idx === 1) setPav([{ ...pav[0] }, { ...pav[1], total: pavArray }, { ...pav[2] }]);
    if (idx === 2) setPav([{ ...pav[0] }, { ...pav[1] }, { ...pav[2], total: pavArray }]);
  }

  function loadUni(empId, pavimento) {
    filterUnidadesRequest(empId, pavimento);
  }

  return (
    <>
      <Header />
      <Container>
        <Desktop>
          <Empreendimentos>
            <div className="square">Em&shy;pre&shy;en&shy;di&shy;men&shy;tos</div>
            {!!unidades[0] ? (
              <Empreendimento>
                <div role="button" tabIndex={0} onClick={() => removeCompare(0)}>
                  <img src={CloseIcon} alt="Fechar" />
                </div>
                <img src={unidades[0].fachada.sizes.large} alt={unidades[0].fachada.title} />
                <img src={unidades[0].logo.sizes.large} alt={unidades[0].logo.title} />
              </Empreendimento>
            ) : (
              <SelectEmp>
                <h2>Adicione uma unidade para comparar</h2>
                <div className="select">
                  <SelectUi
                    name="empreendimento"
                    width="100%"
                    value={emp[0]}
                    borderColor="#6abd45"
                    iconColor="#4a4a4a"
                    handleChange={(e) => {
                      setEmp([e.target.value, emp[1], emp[2]]);
                      loadPav(e.target.value, 0);
                    }}
                    options={empreendimentos.map(e => ({
                      value: e.id,
                      label: e.title.rendered,
                    }))}
                    placeholder="Selecione o empreendimento"
                  />
                  <SelectUi
                    name="empreendimento"
                    width="100%"
                    value={pav[0].value}
                    borderColor="#6abd45"
                    iconColor="#4a4a4a"
                    handleChange={(e) => {
                      setPav([{ ...pav[0], value: e.target.value }, { ...pav[1] }, { ...pav[2] }]);
                      loadUni(emp[0], e.target.value);
                    }}
                    options={pav[0].total}
                    placeholder="Selecione o pavimento"
                    disabled={emp[0] === ''}
                  />
                  <SelectUi
                    name="empreendimento"
                    width="100%"
                    value={uni[0]}
                    borderColor="#6abd45"
                    iconColor="#4a4a4a"
                    handleChange={(e) => {
                      setUni([e.target.value, uni[1], uni[2]]);
                    }}
                    options={filtredUnidades.map(u => ({ value: u, label: `Unidade ${u.unidade}` }))}
                    placeholder="Selecione a unidade"
                    disabled={pav[0].value === ''}
                  />
                </div>
                <BgButton
                  handleClick={() => setCompare(uni[0])}
                  width="calc(100% - 30px)"
                  bgColor="#6abd45"
                  bgColorHover="#84d660"
                >
                  Comparar
                </BgButton>
              </SelectEmp>
            )}
            {!!unidades[1] ? (
              <Empreendimento>
                <div role="button" tabIndex={0} onClick={() => removeCompare(1)}>
                  <img src={CloseIcon} alt="Fechar" />
                </div>
                <img src={unidades[1].fachada.sizes.large} alt={unidades[1].fachada.title} />
                <img src={unidades[1].logo.sizes.large} alt={unidades[1].logo.title} />
              </Empreendimento>
            ) : (
              <SelectEmp>
                <h2>Adicione uma unidade para comparar</h2>
                <div className="select">
                  <SelectUi
                    name="empreendimento"
                    width="100%"
                    value={emp[1]}
                    borderColor="#6abd45"
                    iconColor="#4a4a4a"
                    handleChange={(e) => {
                      setEmp([emp[0], e.target.value, emp[2]]);
                      loadPav(e.target.value, 1);
                    }}
                    options={empreendimentos.map(e => ({
                      value: e.id,
                      label: e.title.rendered,
                    }))}
                    placeholder="Selecione o empreendimento"
                  />
                  <SelectUi
                    name="empreendimento"
                    width="100%"
                    value={pav[1].value}
                    borderColor="#6abd45"
                    iconColor="#4a4a4a"
                    handleChange={(e) => {
                      setPav([{ ...pav[0] }, { ...pav[1], value: e.target.value }, { ...pav[2] }]);
                      loadUni(emp[1], e.target.value);
                    }}
                    options={pav[1].total}
                    placeholder="Selecione o pavimento"
                    disabled={emp[1] === ''}
                  />
                  <SelectUi
                    name="empreendimento"
                    width="100%"
                    value={uni[1]}
                    borderColor="#6abd45"
                    iconColor="#4a4a4a"
                    handleChange={(e) => {
                      setUni([uni[0], e.target.value, uni[2]]);
                    }}
                    options={filtredUnidades.map(u => ({ value: u, label: `Unidade ${u.unidade}` }))}
                    placeholder="Selecione a unidade"
                    disabled={pav[1].value === ''}
                  />
                </div>
                <BgButton
                  handleClick={() => setCompare(uni[1])}
                  width="calc(100% - 30px)"
                  bgColor="#6abd45"
                  bgColorHover="#84d660"
                >
                  Comparar
                </BgButton>
              </SelectEmp>
            )}
            {!!unidades[2] ? (
              <Empreendimento>
                <div role="button" tabIndex={0} onClick={() => removeCompare(2)}>
                  <img src={CloseIcon} alt="Fechar" />
                </div>
                <img src={unidades[2].fachada.sizes.large} alt={unidades[2].fachada.title} />
                <img src={unidades[2].logo.sizes.large} alt={unidades[2].logo.title} />
              </Empreendimento>
            ) : (
              <SelectEmp>
                <h2>Adicione uma unidade para comparar</h2>
                <div className="select">
                  <SelectUi
                    name="empreendimento"
                    width="100%"
                    value={emp[2]}
                    borderColor="#6abd45"
                    iconColor="#4a4a4a"
                    handleChange={(e) => {
                      setEmp([emp[0], emp[1], e.target.value]);
                      loadPav(e.target.value, 2);
                    }}
                    options={empreendimentos.map(e => ({
                      value: e.id,
                      label: e.title.rendered,
                    }))}
                    placeholder="Selecione o empreendimento"
                  />
                  <SelectUi
                    name="empreendimento"
                    width="100%"
                    value={pav[2].value}
                    borderColor="#6abd45"
                    iconColor="#4a4a4a"
                    handleChange={(e) => {
                      setPav([{ ...pav[0] }, { ...pav[1] }, { ...pav[2], value: e.target.value }]);
                      loadUni(emp[2], e.target.value);
                    }}
                    options={pav[2].total}
                    placeholder="Selecione o pavimento"
                    disabled={emp[2] === ''}
                  />
                  <SelectUi
                    name="empreendimento"
                    width="100%"
                    value={uni[2]}
                    borderColor="#6abd45"
                    iconColor="#4a4a4a"
                    handleChange={(e) => {
                      setUni([uni[0], uni[1], e.target.value]);
                    }}
                    options={filtredUnidades.map(u => ({ value: u, label: `Unidade ${u.unidade}` }))}
                    placeholder="Selecione a unidade"
                    disabled={pav[2].value === ''}
                  />
                </div>
                <BgButton
                  handleClick={() => setCompare(uni[2])}
                  width="calc(100% - 30px)"
                  bgColor="#6abd45"
                  bgColorHover="#84d660"
                >
                  Comparar
                </BgButton>
              </SelectEmp>
            )}
          </Empreendimentos>
          <Comparativo>
            <h2>Caracteristicas</h2>

            <Infos>
              <div>
                <div>Pavimento</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.pavimento}</div>
                ))}
              </div>
              <div>
                <div>Unidade</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.unidade}</div>
                ))}
              </div>
              <div>
                <div>N° de quartos</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.quartos}</div>
                ))}
              </div>
              <div>
                <div>Nº de suítes</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.suites}</div>
                ))}
              </div>
              <div>
                <div>Nº de vagas garagem</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.nr_garagem}</div>
                ))}
              </div>
              <div>
                <div>Churrasqueira</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.diferenciais.churrasqueira ? 'Sim' : 'Não'}</div>
                ))}
              </div>
              <div>
                <div>Área total</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{`${unidade.nr_m2_total}m²`}</div>
                ))}
              </div>
              <div>
                <div>Área privativa</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{`${unidade.nr_m2}m²`}</div>
                ))}
              </div>
            </Infos>
          </Comparativo>

          <Comparativo>
            <h2>Diferenciais</h2>

            <Infos>
              <div>
                <div>Salão de festas</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.diferenciais.salao_festas ? 'Sim' : 'Não'}</div>
                ))}
              </div>
              <div>
                <div>Playground</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.diferenciais.playground ? 'Sim' : 'Não'}</div>
                ))}
              </div>
              <div>
                <div>Espaço fitness</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.diferenciais.espaco_fitness ? 'Sim' : 'Não'}</div>
                ))}
              </div>
              <div>
                <div>Salão de jogos</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.diferenciais.salao_jogos ? 'Sim' : 'Não'}</div>
                ))}
              </div>
              <div>
                <div>Quadra poliesportiva</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>
                    {unidade.diferenciais.quadra_poliesportiva ? 'Sim' : 'Não'}
                  </div>
                ))}
              </div>
              <div>
                <div>Espaço gourmet</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.diferenciais.espaco_gourmet ? 'Sim' : 'Não'}</div>
                ))}
              </div>
              <div>
                <div>Espaço kids</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.diferenciais.espaco_kids ? 'Sim' : 'Não'}</div>
                ))}
              </div>
              <div>
                <div>Bicicletário</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.diferenciais.bicicletario ? 'Sim' : 'Não'}</div>
                ))}
              </div>
              <div>
                <div>Piscina aquecida</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.diferenciais.piscina_aquecida ? 'Sim' : 'Não'}</div>
                ))}
              </div>
            </Infos>
          </Comparativo>

          <Comparativo>
            <h2>Demais informações</h2>

            <Infos>
              <div>
                <div>Bairro</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.bairro}</div>
                ))}
              </div>
              <div>
                <div>Endereço</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{`${unidade.endereco} - ${unidade.numero}`}</div>
                ))}
              </div>
              <div>
                <div>Valor</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>
                    {`R$ ${parseInt(unidade.valor, 10).toLocaleString('pt-BR')},00`}
                  </div>
                ))}
              </div>
            </Infos>
          </Comparativo>
        </Desktop>
        <Mobile>
          <EmpreendimentosMobile>
            <div className="square">Em&shy;pre&shy;en&shy;di&shy;men&shy;tos</div>
            <Empreendimento>
              <img
                src="http://api.miquelao.com.br/wp-content/uploads/2019/03/fachada-1.jpg"
                alt="Empreendimento"
              />
              <img
                src="http://api.miquelao.com.br/wp-content/uploads/2019/03/Ducalle_Marca.png"
                alt="Logo"
              />
            </Empreendimento>
            <Empreendimento>
              <img
                src="http://api.miquelao.com.br/wp-content/uploads/2019/03/fachada-1.jpg"
                alt="Empreendimento"
              />
              <img
                src="http://api.miquelao.com.br/wp-content/uploads/2019/03/Ducalle_Marca.png"
                alt="Logo"
              />
            </Empreendimento>
            <SelectEmp>
              <h2>Adicione uma unidade para comparar</h2>
              <div className="select">
                <SelectUi
                  name="empreendimento"
                  width="100%"
                  borderColor="#6abd45"
                  iconColor="#4a4a4a"
                />
                <SelectUi
                  name="empreendimento"
                  width="100%"
                  borderColor="#6abd45"
                  iconColor="#4a4a4a"
                />
              </div>
              <BgButton width="calc(100% - 30px)" bgColor="#6abd45" bgColorHover="#84d660">
                Comparar
              </BgButton>
            </SelectEmp>
          </EmpreendimentosMobile>
          <Comparativo>
            <h2>Caracteristicas</h2>

            <InfosMobile>
              <div>
                <div>Pavimento</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.pavimento}</div>
                ))}
              </div>
              <div>
                <div>Unidade</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.unidade}</div>
                ))}
              </div>
              <div>
                <div>N° de quartos</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.quartos}</div>
                ))}
              </div>
              <div>
                <div>Nº de suítes</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.suites}</div>
                ))}
              </div>
              <div>
                <div>Nº de vagas garagem</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.nr_garagem}</div>
                ))}
              </div>
              <div>
                <div>Churrasqueira</div>
                <div>Sim</div>
                <div>Sim</div>
              </div>
              <div>
                <div>Área total</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{`${unidade.nr_m2_total}m²`}</div>
                ))}
              </div>
              <div>
                <div>Área privativa</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{`${unidade.nr_m2}m²`}</div>
                ))}
              </div>
            </InfosMobile>
          </Comparativo>

          <Comparativo>
            <h2>Diferenciais</h2>

            <InfosMobile>
              <div>
                <div>Salão de festas</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.diferenciais.salao_festas ? 'Sim' : 'Não'}</div>
                ))}
              </div>
              <div>
                <div>Playground</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.diferenciais.playground ? 'Sim' : 'Não'}</div>
                ))}
              </div>
              <div>
                <div>Espaço fitness</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.diferenciais.espaco_fitness ? 'Sim' : 'Não'}</div>
                ))}
              </div>
              <div>
                <div>Salão de jogos</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.diferenciais.salao_jogos ? 'Sim' : 'Não'}</div>
                ))}
              </div>
              <div>
                <div>Quadra poliesportiva</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>
                    {unidade.diferenciais.quadra_poliesportiva ? 'Sim' : 'Não'}
                  </div>
                ))}
              </div>
              <div>
                <div>Espaço gourmet</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.diferenciais.espaco_gourmet ? 'Sim' : 'Não'}</div>
                ))}
              </div>
              <div>
                <div>Espaço kids</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.diferenciais.espaco_kids ? 'Sim' : 'Não'}</div>
                ))}
              </div>
              <div>
                <div>Bicicletário</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.diferenciais.bicicletario ? 'Sim' : 'Não'}</div>
                ))}
              </div>
              <div>
                <div>Piscina aquecida</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.diferenciais.piscina_aquecida ? 'Sim' : 'Não'}</div>
                ))}
              </div>
            </InfosMobile>
          </Comparativo>

          <Comparativo>
            <h2>Demais informações</h2>

            <InfosMobile>
              <div>
                <div>Bairro</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{unidade.bairro}</div>
                ))}
              </div>
              <div>
                <div>Endereço</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>{`${unidade.endereco} - ${unidade.numero}`}</div>
                ))}
              </div>
              <div>
                <div>Valor</div>
                {unidades.map(unidade => (
                  <div key={unidade.id}>
                    {`R$ ${parseInt(unidade.valor, 10).toLocaleString('pt-BR')},00`}
                  </div>
                ))}
              </div>
            </InfosMobile>
          </Comparativo>
        </Mobile>
      </Container>

      <Simulador />
      <Footer />
    </>
  );
}

Comparar.propTypes = {
  removeCompare: PropTypes.func.isRequired,
  unidades: PropTypes.arrayOf(PropTypes.shape()),
};

Comparar.defaultProps = {
  unidades: [],
};

const mapStateToProps = state => ({
  unidades: state.comparador.unidades,
  empreendimentos: state.comparador.empreendimentos,
  filtredUnidades: state.comparador.filtredUnidades,
});

const mapDispatchToProps = dispatch => bindActionCreators(ComparadorActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Comparar);
