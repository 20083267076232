import styled from 'styled-components';

export const Container = styled.div`
  overflow-y: auto;
  margin-right: 5%;
`;

export const Empreendimentos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 40px;
  width: 95%;
  max-width: 1400px;
  height: 550px;
  padding: 40px 0;
  margin: auto;

  .square {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    width: 100%;
    font-family: 'Noirden';
    font-size: 30px;
    font-weight: bold;
    hyphens: manual;
    color: #fff;
    background: #6abd45;
    padding: 10%;
  }
`;

export const EmpreendimentosMobile = styled(Empreendimentos)`
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr);
`;

export const Empreendimento = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  div {
    position: absolute;
    top: 10px;
    right: 10px;

    > img {
      height: 25px;
      cursor: pointer;
    }
  }

  > img {
    &:first-of-type {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }

    &:last-of-type {
      width: 100%;
      height: 140px;
      object-fit: contain;
    }
  }
`;

export const SelectEmp = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    width: 80%;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    color: #4a4a4a;
    margin: 0 auto 40px auto;
  }

  .select {
    width: 100%;
    margin-bottom: 69px;
    > div {
      margin-bottom: 30px;
    }
  }
`;

export const Comparativo = styled.div`
  width: 95%;
  max-width: 1400px;
  margin: auto;
  padding: 35px 0;

  &:first-of-type {
    padding: 20px 0 35px 0;
  }

  &:last-of-type {
    padding: 35px 0 100px 0;
  }

  h2 {
    font-family: 'Noirden';
    font-size: 36px;
    font-weight: 300;
    color: #8e8e8e;
  }
`;

export const Infos = styled.div`
  display: grid;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  color: #838282;
  background: #fff;
  border: 4px solid #f3f3f3;
  margin-top: 25px;

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    min-height: 60px;

    &:nth-child(2n) {
      background: #f3f3f3;
    }

    > div {
      &:first-child {
        text-align: left;
        margin-left: 30px;
      }
    }
  }
`;

export const InfosMobile = styled(Infos)`
  border: none;

  > div {
    grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(
        200px,
        1fr
      );
    grid-column-gap: 40px;
  }
`;
